import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { SortDirection } from 'react-virtualized';
import { get, isEmpty, camelCase } from 'lodash';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import useFetchList from 'hooks/useFetchList';
import { AppRouteContext } from 'contexts';
import {
  ASSESSMENT_STATUSES,
  ASSESSMENT_STATUSES_OPTIONS,
  ASSESSMENTS_FOR_PATIENT_FIELDS,
  FAKE_ASSESSMENT,
  FIELDS,
  GET_ASSESSMENTS_COLUMNS,
  INVITATION_TYPE,
} from 'components/Assessments/constants';
import { getLocalizedDate, getLocalizedLabel } from 'helpers/localize';
import assessmentsPlaceholderImage from 'assets/placeholders/orders.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PendingIcon } from 'assets/icons/pending.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info_gray.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/file_download_black.svg';
import { ActivePatientContext } from '../../../contexts/ActivePatientProvider';
import ConfirmResendingInvitation from './widgets/ConfirmResendingInvitation';
import { WARNING_DURATION } from '../../../contexts/NotificationsProvider';
import { NULL_QUERY_DELAY } from '../../Shared/constants';
import PatientAdditionalNode from '../../Patients/PatientAdditionalNode';
import PatientInfoBlock from '../../Patients/PatientInfoBlock';
import { routes } from '../../../config/routes';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../redux_store/reducer/reducers/notificationsReducer';
import EnableClaimModal from '../../ClaimsReporting/widgets/EnableClaimModal';
import getFakeDataList, { FAKE_TITLE_DATA } from '../../Shared/FakeDataGenerator';
import ShowAssessmentLinkModal from './widgets/ShowAssessmentLinkModal';
import { TABLE_KEYS } from '../../../redux_store/reducer/reducers/tableColumnsReducer';
import Actions from './widgets/Actions';
import { CUSTOM_TAGS_OPTIONS, SURVEY_TAGS_OPTIONS } from '../../Shared/CustomTags';
import { downloadFile } from '../../Billing/helpers';
import showServerError from '../../../helpers/showError';
import { useMutation } from 'react-fetching-library';
import { checkPermission } from '../../../helpers/checkPermissions';
import permissions from '../../../config/permissions';
import TableChips from '../../Table/TableChip';

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden',
  },
  dot: {
    padding: theme.spacing(0, 0.5),
    color: theme.colors.lightBlue,
  },
  patientInfoBox: {
    display: 'flex',
  },
  flex: {
    display: 'flex',
  },
  refreshIcon: {
    marginLeft: theme.spacing(2),
  },
}));

/*
  could be rendered in 2 cases:
  - all account assessments
  - assessments for a special user (patientUuid in URL params)
*/

const defaultConfirmResendInvitationState = {
  patientUUID: '',
  survey: '',
  accountUUID: '',
};

const AssessmentsList = () => {
  const { t } = useTranslation([
    'titles',
    'tables',
    'errors',
    'notifications',
    'btn',
    'dialogs',
  ]);
  const classes = useStyles();
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const isPatientPage = Boolean(paramsPatientUuid);
  const dispatch = useDispatch();
  const {
    metadata: { currentAccount, currentAccountPermissions },
  } = useSelector((state) => state.user);
  const [openConfirmResentDialog, toggleConfirmResentDialog] = useState(
    defaultConfirmResendInvitationState,
  );
  const [modifiedAssessments, setModifiedAssessments] = useState();
  const [tableSort, setTableSort] = useState({
    sortBy: 'orderDate',
    sortDirection: SortDirection.DESC,
  });
  const [tableFilter, setTableFilter] = useState();
  const [enableClaimModalInfo, setEnableClaimModalInfo] = useState(null);
  const [filterStyle, setFilterStyle] = useState(false);
  const [assessmentLinkForShareModal, setAssessmentLinkForShareModal] = useState(null); // trigger useOrderMetadata
  const { setAppRoute } = useContext(AppRouteContext);
  const { patientUuid, patientInfo } = useContext(ActivePatientContext);
  const hasLoadFilesPermission = checkPermission(
    currentAccountPermissions,
    permissions.SURVEYS_GET,
  );

  const baseEndpoint = `/accounts/${
    paramAccountUuid || currentAccount.uuid
  }`;

  const {
    items,
    totalAccountsCount,
    totalCount,
    completionRate,
    lookups,
    reloadData,
    clearFilter,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint: baseEndpoint + '/surveys',
    baseParams: {
      fields: (isPatientPage ? ASSESSMENTS_FOR_PATIENT_FIELDS : FIELDS).join(),
      q: isPatientPage ? `patientUUID:${paramsPatientUuid}` : undefined,
    },
    baseSort: {
      sortBy: 'orderDate',
      sortDirection: SortDirection.DESC,
    },
  });

  const { filter, sort } = restFetchProps;
  const [prevTotalCount, setPrevTotalCount] = useState(totalCount);

  useEffect(() => {
    if (prevTotalCount !== totalCount) {
      setFilterStyle(!isEmpty(filter));
      setPrevTotalCount(totalCount);
    }
  }, [filter, totalCount]);

  useEffect(() => {
    if (sort) {
      setTableSort(sort);
    }
    if (filter) {
      setTableFilter(filter);
    }
  }, [sort, filter]);

  useEffect(() => {
    if (items) {
      setModifiedAssessments(
        items.map((assessment) => ({
          ...assessment,
          patientDateOfBirth: getLocalizedDate(
            assessment.patientDateOfBirth,
            'UTC',
          ),
          accountCustomIdentifier: assessment.accountCustomIdentifier
            ? assessment.accountCustomIdentifier
            : 'N/A',
          invitedTimestamp: assessment.invitedTimestamp
            ? getLocalizedDate(assessment.invitedTimestamp, 'UTC')
            : '-',
          appointmentTimestamp: assessment.appointmentTimestamp
            ? getLocalizedDate(assessment.appointmentTimestamp, 'UTC')
            : '-',
          createdBy: assessment.createdBy === 'manuallyCreated'
            ? t('tables:manuallyCreated')
            : assessment.createdBy === 'integration'
              ? t('tables:integration')
              : assessment.createdBy === 'automated'
                ? t('tables:automated')
                : assessment.createdBy,
          patientMrnNumber: !assessment.patientMrnNumber ? '-' : assessment.patientMrnNumber,
          orderDate: getLocalizedDate(assessment.orderDate, 'UTC'),
          status: getLocalizedLabel(
            ASSESSMENT_STATUSES_OPTIONS,
            assessment.status,
          ),
          moreTitle: isPatientPage
            ? `${assessment.surveyName}`
            : `${assessment.patientFirstName} ${assessment.patientLastName}`,
          invitationType: assessment.invitationType,
          claimable: assessment.claimExists === false,
          accountCustomTags: assessment.accountCustomTags ? assessment.accountCustomTags.map(item => getLocalizedLabel(
            CUSTOM_TAGS_OPTIONS,
            item,
          )) : [],
          surveyTags: assessment.surveyTags ? assessment.surveyTags.map(item => getLocalizedLabel(
            SURVEY_TAGS_OPTIONS,
            item,
          )) : [],
        })),
      );
    }
  }, [items]);

  const redirectToAssessmentReport = ({
                                        uuid,
                                        accountUUID,
                                        patientUUID,
                                        surveyName,
                                        status,
                                      }) => {
    setAppRoute({
      path: routes.patientAssessmentReport.path,
      params: {
        accountUuid: accountUUID,
        patientUuid: patientUUID,
        assessmentUuid: uuid,
        status: camelCase(status),
      },
      title: `${surveyName} ${t('titles:report')}`,
    });
  };

  const handleRowClick = (assessment) => {
    const status = camelCase(assessment.status);
    if (
      status === ASSESSMENT_STATUSES.completed ||
      status === ASSESSMENT_STATUSES.inProgress ||
      status === ASSESSMENT_STATUSES.eTransfer ||
      status === ASSESSMENT_STATUSES.pendingReview
    ) {
      redirectToAssessmentReport(assessment);
    } else {
      dispatch(
        enqueueSnackbar({
          message: t('notifications:assessmentMustBeCompleted'),
          options: {
            variant: SNACKBAR_VARIANTS.warning,
            autoHideDuration: WARNING_DURATION,
          },
        }),
      );
    }
  };

  const checkIsDisabled = (assessmentUuid) => {
    if (dataLoading || !modifiedAssessments) {
      return true;
    }
    let isDisabled = false;

    modifiedAssessments.forEach((assessment) => {
      if (assessment.uuid === assessmentUuid) {
        const allowed = assessment.claimable && assessment.status === getLocalizedLabel(
          ASSESSMENT_STATUSES_OPTIONS,
          ASSESSMENT_STATUSES.completed,
        );
        isDisabled = !allowed;
      }
    });
    return isDisabled;
  };

  const checkIsResendingDisabled = (assessmentUuid) => {
    if (dataLoading || !modifiedAssessments) {
      return true;
    }
    let isDisabled = false;

    modifiedAssessments.forEach((assessment) => {
      if (assessment.uuid === assessmentUuid) {
        isDisabled = assessment.invitationType === INVITATION_TYPE.notAllowed;
      }
    });

    return isDisabled;
  };

  const checkIsDownloadProgramPDFDisabled = (assessmentUuid) => {
    if (dataLoading || !modifiedAssessments || !hasLoadFilesPermission) {
      return true;
    }
    let isDisabled = true;

    modifiedAssessments.forEach((assessment) => {
      if (assessment.uuid === assessmentUuid && assessment.status === getLocalizedLabel(
        ASSESSMENT_STATUSES_OPTIONS,
        ASSESSMENT_STATUSES.completed,
      )) {
        isDisabled = false;
      }
    });

    return isDisabled;
  };

  const updateClaimableByUuid = (resourceUuid) => {
    const newModifiedItems = modifiedAssessments.map((item) => {
      if (item.uuid === resourceUuid) {
        return {
          ...item,
          claimable: false,
        };
      }
      return item;
    });
    setModifiedAssessments(newModifiedItems);
  };

  const handleConfirmResentDialog = (
    e,
    {
      patientUUID,
      uuid,
      sendInviteToPatientEmail,
      sendInviteToPatientPhone,
      accountUUID,
      invitationType,
    },
  ) => {
    toggleConfirmResentDialog({
      sendInviteToPatientEmail,
      sendInviteToPatientPhone,
      patientUUID,
      survey: uuid,
      accountUUID,
      invitationType,
    });
  };

  const reloadAssessmentsList = (hasFilter) => {
    if (!hasFilter) {
      clearFilter();
    } else {
      reloadData({ delay: NULL_QUERY_DELAY });
    }
  };

  const patientFirstName = get(patientInfo, 'firstName', '');
  const patientLastName = get(patientInfo, 'lastName', '');
  const titleText = isPatientPage
    ? (patientInfo && `${patientLastName}, ${patientFirstName}`) ||
    FAKE_TITLE_DATA
    : t('titles:assessments');

  const isTableDataLoading = dataLoading || !modifiedAssessments;
  const FAKE_ASSESSMENT_LIST = getFakeDataList(FAKE_ASSESSMENT);

  const importFile = (assessment) => ({
    method: 'GET',
    endpoint: `/accounts/${assessment.accountUUID}/surveys/${assessment.uuid}/program-report/pdf`,
    responseType: 'blob',
  });

  const { mutate: importMutate } = useMutation(importFile);

  const downloadProgramPDF = async (e, assessment) => {
    const { payload, error, status } = await importMutate(assessment);
    if (!error) {
      downloadFile(
        new Blob([payload]),
        `${assessment.surveyName} - ${assessment.patientLastName} ${assessment.patientFirstName} ${assessment.orderDate}.pdf`);
      dispatch(
        enqueueSnackbar({
          message: t('notifications:pdfSucceededSaved'),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
    } else {
      switch (status) {
        case 422: {
          const obj = JSON.parse(await payload.text());
          Object.keys(obj.validationErrors).forEach(key =>
            showServerError(dispatch, obj.validationErrors[key]));
          break;
        }
        default: {
          showServerError(dispatch, t('errors:unknownError'));
          break;
        }
      }
    }
  };

  const columns = useMemo(
    () => GET_ASSESSMENTS_COLUMNS(
      isPatientPage,
      paramAccountUuid,
      totalAccountsCount,
    ),
    [totalAccountsCount, isPatientPage, paramAccountUuid],
  );

  return (
    <>
      {enableClaimModalInfo && (
        <EnableClaimModal
          open
          handleClose={() => setEnableClaimModalInfo(null)}
          codeType='assessment'
          accountUuid={enableClaimModalInfo.accountUUID}
          uuid={enableClaimModalInfo.uuid}
          updateClaimableByUuid={updateClaimableByUuid}
        />
      )}
      {openConfirmResentDialog.survey && (
        <ConfirmResendingInvitation
          source='survey'
          handleClose={() =>
            toggleConfirmResentDialog(defaultConfirmResendInvitationState)
          }
          reloadData={reloadData}
          resendInvitationData={openConfirmResentDialog}
          subtitle={t('forms:invitationWillBeSentSubtitle')}
        />
      )}
      <ShowAssessmentLinkModal
        open={!!assessmentLinkForShareModal}
        handleClose={() => setAssessmentLinkForShareModal(null)}
        assessmentLink={assessmentLinkForShareModal}
      />
      <ContentWrapper
        titleText={titleText}
        className={classes.paper}
        hasTopPaddingForSmallScreen={isPatientPage}
        underTitleText={
          isPatientPage ? (
            <PatientInfoBlock
              isForInitLoading={
                (!items && patientUuid !== paramsPatientUuid) || !patientInfo
              }
            />
          ) : (
            ''
          )
        }
        count={isPatientPage ? null : totalCount || FAKE_TITLE_DATA}
        countFiltered={filterStyle}
        actions={
          <Actions
            reloadList={reloadAssessmentsList}
            dataLoading={dataLoading}
            baseEndpoint={baseEndpoint}
            tableSort={tableSort}
            tableFilter={tableFilter}
            totalAccountsCount={totalAccountsCount}
          />
        }
        additionalNode={
          isPatientPage ? (
            <PatientAdditionalNode
              activeItemNumber={0}
              modifiedData={modifiedAssessments}
              totalCount={totalCount}
              reloadData={reloadAssessmentsList}
              dataLoading={dataLoading}
              historyTitle={t('tables:assessmentsHistory')}
            />
          ) : <TableChips aggregations={(completionRate || completionRate === 0) ? {
            completionRate: (completionRate * 100).toFixed(1) + '%',
          } : {}} />
        }
      >
        <VirtualizedTable
          tableKey={paramAccountUuid ? TABLE_KEYS.assessmentsDS : TABLE_KEYS.assessments}
          hasColumnsSelect={true}
          fakeRows={FAKE_ASSESSMENT_LIST}
          rowCount={totalCount}
          rows={modifiedAssessments}
          columns={columns}
          lookups={lookups}
          onRowClick={handleRowClick}
          FetchProps={{ ...restFetchProps }}
          onDataLoading={isTableDataLoading}
          currentAccountPermissions={currentAccountPermissions}
          actionProps={{
            actions: [
              {
                key: 'resendInvitationAction',
                icon: PendingIcon,
                onActionClick: handleConfirmResentDialog,
                children: t('titles:resendInvitation'),
                isDisabled: checkIsResendingDisabled,
              },
              {
                key: 'claimableAction',
                icon: EditIcon,
                onActionClick: (e, claim) => {
                  setEnableClaimModalInfo(claim);
                },
                children: t('titles:generateClaim'),
                isDisabled: checkIsDisabled,
              },
              {
                key: 'viewAssessmentLink',
                icon: InfoIcon,
                onActionClick: (e, claim) => {
                  setAssessmentLinkForShareModal(claim.url);
                },
                children: t('titles:viewAssessmentLink'),
              },
              {
                key: 'downloadProgram',
                icon: DownloadIcon,
                onActionClick: downloadProgramPDF,
                children: t('titles:downloadProgram'),
                isDisabled: checkIsDownloadProgramPDFDisabled,
              },
            ],
          }}
          placeholder={
            <TablePlaceholder
              image={assessmentsPlaceholderImage}
              itemsNotFoundText={t('placeholders:noItemsFound', {
                item: t('titles:assessments'),
              })}
              message={t('placeholders:reviseFiltersOrCreateYourFirstItem', {
                item: t('titles:assessment'),
              })}
            />
          }
        />
      </ContentWrapper>
    </>
  );
};

export default AssessmentsList;
