import i18next from 'i18next';
import permissions from '../../config/permissions';
import FILTER_TYPES from '../Filters/constants';
import { CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';
import { ReactComponent as ShippedIcon } from '../../assets/icons/shipped.svg';
import { ReactComponent as PendingIcon } from '../../assets/icons/pending.svg';
import { currencyFormat } from '../Table/helpers';

export const CLAIM_REPORTS_STATUSES = {
  reported: 'reported',
  reportable: 'reportable',
};

export const CLAIM_REPORTS_STATUSES_OPTIONS = [
  {
    label: i18next.t('tables:reported'),
    value: CLAIM_REPORTS_STATUSES.reported,
    icon: ShippedIcon,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:reportable'),
    value: CLAIM_REPORTS_STATUSES.reportable,
    icon: PendingIcon,
    enabledForNonSponsors: false,
  },
];

export const FIELDS = [
  'accountBusinessName',
  'accountCustomIdentifier',
  'accountCustomTags',
  'totalAmount',
  'totalCPTs',
  'createdBy',
  'claimReportGeneratedTimestamp',
];

export const CLAIM_REPORTS_FOR_PATIENT_FIELDS = [
  'totalAmount',
  'totalCPTs',
  'createdBy',
  'claimReportGeneratedTimestamp',
];

export const GET_CLAIM_REPORTS_COLUMNS = (
  isPatientPage,
  paramAccountUuid,
  totalAccountsCount,
) => [
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:customTags'),
    dataKey: 'accountCustomTags',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CUSTOM_TAGS_OPTIONS,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
  },
  {
    width: 220,
    label: i18next.t('tables:account'),
    dataKey: 'accountBusinessName',
    columnData: {
      isFilterable: true,
      totalAccountsCount,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    flexGrow: 1,
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 120,
    label: i18next.t('tables:customId'),
    dataKey: 'accountCustomIdentifier',
    columnData: {
      isFilterable: true,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:createdBy'),
    dataKey: 'createdBy',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:totalAmount'),
    dataKey: 'totalAmount',
    columnData: {
      isFilterable: true,
      formattedFunction: currencyFormat,
      filterType: FILTER_TYPES.PRICE,
    },
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:totalCPTs'),
    dataKey: 'totalCPTs',
    columnData: {
      isFilterable: true,
    },
  },
  {
    width: 240,
    label: i18next.t('tables:claimReportGeneratedTimestamp'),
    dataKey: 'claimReportGeneratedTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
];

export const FAKE_CLAIM_REPORT = {
  accountCustomTags: [],
  claimReportGeneratedTimestamp: '',
  accountCustomIdentifier: '',
  accountBusinessName: '',
  totalAmount: '0',
  totalCPTs: '0',
  createdBy: '',
};
