import i18next from 'i18next';

import Login from 'components/Public/Login';
import PasswordReset from 'components/Public/PasswordReset';
import Terms from 'components/Public/Terms';
import Privacy from 'components/Public/Privacy';
import AcceptInvitation from 'components/Public/AcceptInvitation';
import Home from 'components/Dashboard/Home';
import DashboardComponent from 'components/Dashboard/DashboardComponent';
import ProfileEdit from 'components/Dashboard/ProfileEdit';
import PasswordChange from 'components/Dashboard/PasswordChange';
import AccountsList from 'components/Accounts/List/AccountsList';
import AccountCreate from 'components/Accounts/Item/AccountCreate';
import AccountEdit from 'components/Accounts/Item/AccountEdit';
import UsersList from 'components/Users/List/UsersList';
import PatientsList from 'components/Patients/PatientsList';
import OrdersList from 'components/Orders/OrdersList';
import OrderCreate from 'components/Orders/OrderCreate';
import AssessmentsList from 'components/Assessments/List/AssessmentsList';
import BillingList from 'components/Billing/BillingList';
import NotFound from 'components/Public/NotFound';
import UserCreate from 'components/Users/Item/UserCreate';
import UserEdit from 'components/Users/Item/UserEdit';
import OrderEdit from 'components/Orders/OrderEdit';
import permissions from 'config/permissions';
import DashboardLogo from 'components/Shared/Icons/DashboardLogo';
import AccountsLogo from 'components/Shared/Icons/AccountsLogo';
import PatientsLogo from 'components/Shared/Icons/PatientsLogo';
import OrdersLogo from 'components/Shared/Icons/OrdersLogo';
import AssessmentsLogo from 'components/Shared/Icons/AssessmentsLogo';
import ReportsLogo from 'components/Shared/Icons/ReportsLogo';
import ReportView from 'components/Reports/ReportView';
import AllergiEndResourcesLogo from '../components/Shared/Icons/AllergiEndResourcesLogo';
import ResourcesList from '../components/Resources/ResourcesList';
import PatientUpload from '../components/PatientUpload/PationtUpload';
import ClaimsReportingList from '../components/ClaimsReporting/List/ClaimsReportingList';
import EditClaim from '../components/ClaimsReporting/Item/EditClaim';
import ClaimReportList from '../components/ClaimReport/List/СlaimReportList';
import ReportablesList from '../components/Reportable/List/ReportablesList';
import InsuranceUpload from '../components/InsuranceUpload/InsuranceUpload';
import ProvidersUpload from '../components/ProvidersUpload/ProvidersUpload';
import DownForMaintenance from '../components/Public/DownForMaintenance';

// Public routes
const downForMaintenance = {
  path: '/down-for-maintenance',
  exact: true,
  additional: {
    component: DownForMaintenance,
    protected: false,
  },
};

const login = {
  path: '/login',
  exact: true,
  additional: {
    component: Login,
    protected: false,
  },
};
const passwordReset = {
  path: '/password/reset',
  exact: true,
  additional: {
    component: PasswordReset,
    protected: false,
  },
};
const terms = {
  path: '/terms',
  exact: true,
  additional: {
    component: Terms,
    protected: false,
  },
};
const privacy = {
  path: '/privacy',
  exact: true,
  additional: {
    component: Privacy,
    protected: false,
  },
};
const acceptInvitation = {
  path: '/invite/:token',
  exact: true,
  additional: {
    component: AcceptInvitation,
    protected: false,
  },
};

// Dashboard
const root = {
  path: '/',
  exact: true,
  additional: {
    component: Home,
    title: i18next.t('titles:dashboard'),
    protected: true,
  },
};
const dashboard = {
  path: '/dashboard',
  exact: true,
  additional: {
    component: DashboardComponent,
    title: i18next.t('titles:dashboard'),
    protected: true,
    icon: DashboardLogo,
    permission: permissions.DASHBOARD_GET,
    disabledId: 'dashboard',
  },
};
const editProfile = {
  path: '/profile',
  exact: true,
  additional: {
    component: ProfileEdit,
    protected: true,
    title: i18next.t('titles:editProfile'),
  },
};
const changePassword = {
  path: '/password',
  exact: true,
  additional: {
    component: PasswordChange,
    protected: true,
    title: i18next.t('titles:changePassword'),
  },
};

// Accounts
const accountsList = {
  path: '/accounts/list',
  exact: true,
  additional: {
    component: AccountsList,
    protected: true,
    title: i18next.t('titles:accounts'),
    icon: AccountsLogo,
    permission: permissions.ACCOUNTS_GET,
  },
};
const accountCreate = {
  path: '/accounts/create',
  exact: true,
  additional: {
    component: AccountCreate,
    protected: true,
    title: i18next.t('titles:createAccount'),
  },
};
const accountEdit = {
  path: '/accounts/:accountUuid/edit',
  exact: true,
  parentRoute: accountsList,
  additional: {
    component: AccountEdit,
    protected: true,
    title: i18next.t('titles:editAccount'),
  },
};
const accountUsers = {
  path: '/accounts/:accountUuid/users/list',
  exact: true,
  parentRoute: accountsList,
  additional: {
    component: UsersList,
    protected: true,
    title: i18next.t('titles:accountUsers'),
  },
};
const accountUserCreate = {
  path: '/accounts/:accountUuid/users/create',
  exact: true,
  parentRoute: accountsList,
  additional: {
    component: UserCreate,
    protected: true,
    title: i18next.t('titles:createUser'),
  },
};
const accountUserEdit = {
  path: '/accounts/:accountUuid/users/:userUuid/edit',
  exact: true,
  parentRoute: accountsList,
  additional: {
    component: UserEdit,
    protected: true,
    title: i18next.t('titles:editUser'),
  },
};

// Patients
const patientsList = {
  path: '/patients/list',
  exact: true,
  additional: {
    component: PatientsList,
    protected: true,
    title: i18next.t('titles:patients'),
    icon: PatientsLogo,
  },
};
const accountPatientsList = {
  path: '/accounts/:accountUuid/patients/list',
  exact: true,
  parentRoute: patientsList,
  additional: {
    component: PatientsList,
    protected: true,
    title: i18next.t('titles:patients'),
    icon: PatientsLogo,
  },
};

const patientOrdersList = {
  path: '/accounts/:accountUuid/patients/:patientUuid/orders/list',
  exact: true,
  parentRoute: patientsList,
  additional: {
    component: OrdersList,
    protected: true,
    title: i18next.t('titles:orders'),
    icon: OrdersLogo,
  },
};

const patientOrdersListShowPopup = {
  path:
    '/accounts/:accountUuid/patients/:patientUuid/orders/create-assessment/list',
  exact: true,
  parentRoute: patientsList,
  additional: {
    component: OrdersList,
    protected: true,
    title: i18next.t('titles:orders'),
    icon: OrdersLogo,
  },
};


const patientAssessmentListShowPopup = {
  path:
    '/accounts/:accountUuid/patients/:patientUuid/assessments/create-assessment/list',
  exact: true,
  parentRoute: patientsList,
  additional: {
    component: AssessmentsList,
    protected: true,
    title: i18next.t('titles:assessments'),
    icon: AssessmentsLogo,
  },
};

const patientOrderCreate = {
  path:
    '/accounts/:accountUuid/patients/:patientUuid/orders/:reorderUuid/create',
  exact: true,
  parentRoute: patientsList,
  additional: {
    component: OrderCreate,
    protected: true,
    title: i18next.t('titles:createOrder'),
  },
};
const patientOrderEdit = {
  path: '/accounts/:accountUuid/patients/:patientUuid/orders/:orderUuid/edit',
  exact: true,
  parentRoute: patientsList,
  additional: {
    component: OrderEdit,
    protected: true,
    title: i18next.t('titles:editOrder'),
  },
};

// Orders
const ordersList = {
  path: '/orders/list',
  exact: true,
  additional: {
    component: OrdersList,
    protected: true,
    title: i18next.t('titles:orders'),
    icon: OrdersLogo,
  },
};

// Assessments
const patientAssessmentCreate = {
  path: '/accounts/:accountUuid/patients/:patientUuid/assessments/create',
  exact: true,
  parentRoute: patientsList,
  additional: {
    component: AssessmentsList,
    protected: true,
    title: i18next.t('titles:createAssessment'),
  },
};

const assessmentsList = {
  path: '/assessments/list',
  exact: true,
  additional: {
    component: AssessmentsList,
    protected: true,
    title: i18next.t('titles:assessments'),
    icon: AssessmentsLogo,
  },
};

const patientAssessmentsList = {
  path: '/accounts/:accountUuid/patients/:patientUuid/assessments/list',
  exact: true,
  parentRoute: assessmentsList,
  additional: {
    component: AssessmentsList,
    protected: true,
    title: i18next.t('titles:assessments'),
    icon: AssessmentsLogo,
  },
};

const patientAssessmentReport = {
  path:
    '/accounts/:accountUuid/patients/:patientUuid/assessments/:assessmentUuid/:status/report',
  exact: true,
  parentRoute: assessmentsList,
  additional: {
    component: ReportView,
    protected: true,
    title: i18next.t('titles:reports'),
  },
};

// Reports
const billingList = {
  path: '/billing/list',
  exact: true,
  additional: {
    component: BillingList,
    protected: true,
    title: i18next.t('titles:billingReports'),
    icon: ReportsLogo,
  },
};

// Billing
const patientBillingList = {
  path: '/accounts/:accountUuid/patients/:patientUuid/billing/list',
  exact: true,
  parentRoute: billingList,
  additional: {
    component: BillingList,
    protected: true,
    title: i18next.t('titles:billingReports'),
  },
};

const resourcesList = {
  path: '/resources/list',
  exact: true,
  additional: {
    component: ResourcesList,
    protected: true,
    title: i18next.t('titles:resources'),
    icon: AllergiEndResourcesLogo,
    permission: permissions.GET_RESOURCES,
  },
};

const patientResourcesList = {
  path: '/accounts/:accountUuid/patients/:patientUuid/resources/list',
  exact: true,
  parentRoute: resourcesList,
  additional: {
    component: ResourcesList,
    protected: true,
    title: i18next.t('titles:resources'),
    icon: AllergiEndResourcesLogo,
  },
};

// Not found
const notFoundProtected = {
  path: '/not-found',
  exact: true,
  additional: {
    component: NotFound,
    protected: true,
    title: i18next.t('titles:notFound'),
  },
};

const allergiEndResources = {
  externalReference: true,
  path:
    'https://drive.google.com/drive/u/4/folders/1g_jpa1rkycfdaq-NizLUZkFQZ-tDaagK',
  exact: true,
  additional: {
    component: NotFound,
    protected: true,
    title: i18next.t('titles:allergiEndResources'),
    icon: AllergiEndResourcesLogo,
    permission: permissions.ACCOUNT_GET_ALLERGIEND_RESOURCES,
  },
};

const claimsReportingList = {
  path: '/claimsReporting',
  exact: true,
  additional: {
    component: ClaimsReportingList,
    title: i18next.t('titles:claimsReporting'),
    protected: true,
    icon: DashboardLogo,
    permission: permissions.CLAIMS_GET,
  },
};

const claimReportList = {
  path: '/claimReport/list',
  exact: true,
  additional: {
    component: ClaimReportList,
    protected: true,
    title: i18next.t('titles:claimReports'),
    icon: OrdersLogo,
    permission: permissions.CLAIM_REPORTS_GET,
  },
};

const patientClaimReportList = {
  path: '/accounts/:accountUuid/patients/:patientUuid/claim-report/list',
  exact: true,
  parentRoute: claimReportList,
  additional: {
    component: ClaimReportList,
    protected: true,
    title: i18next.t('titles:claimReports'),
    icon: OrdersLogo,
    permission: permissions.CLAIM_REPORTS_GET,
  },
};

const reportablesList = {
  path: '/reportables/list',
  exact: true,
  additional: {
    component: ReportablesList,
    protected: true,
    title: i18next.t('titles:reportables'),
    icon: OrdersLogo,
    permission: permissions.CLAIM_REPORTS_GET,
  },
};

const patientReportableList = {
  path: '/accounts/:accountUuid/patients/:patientUuid/reportables/list',
  exact: true,
  parentRoute: claimReportList,
  additional: {
    component: ClaimReportList,
    protected: true,
    title: i18next.t('titles:reportables'),
    icon: OrdersLogo,
    permission: permissions.CLAIM_REPORTS_GET,
  },
};

const editClaim = {
  path: '/accounts/:accountUuid/claim/:claimUuid',
  exact: true,
  parentRoute: claimsReportingList,
  additional: {
    component: EditClaim,
    title: i18next.t('titles:editClaim'),
    protected: true,
    icon: DashboardLogo,
    permission: permissions.CLAIMS_GET,
  },
};

const patientClaimsReportingList = {
  path: '/accounts/:accountUuid/patients/:patientUuid/claimsReporting/list',
  parentRoute: claimsReportingList,
  exact: true,
  additional: {
    component: ClaimsReportingList,
    title: i18next.t('titles:claimsReporting'),
    protected: true,
    icon: DashboardLogo,
    permission: permissions.CLAIMS_GET,
  },
};

const patientUpload = {
  path: '/accounts/:accountUuid/:title/patientUpload',
  exact: true,
  additional: {
    component: PatientUpload,
    title: i18next.t('titles:patientUpload'),
    protected: true,
    icon: DashboardLogo,
    permission: permissions.ADMIN_PATIENT_IMPORT,
  },
};

const insuranceUpload = {
  path: '/accounts/:accountUuid/:title/insuranceUpload',
  exact: true,
  additional: {
    component: InsuranceUpload,
    title: i18next.t('titles:insuranceUpload'),
    protected: true,
    icon: DashboardLogo,
    permission: permissions.ADMIN_PATIENT_IMPORT,
  },
};

const providersUpload = {
  path: '/accounts/:accountUuid/:title/providersUpload',
  exact: true,
  additional: {
    component: ProvidersUpload,
    title: i18next.t('titles:providersUpload'),
    protected: true,
    icon: DashboardLogo,
    permission: permissions.ADMIN_PATIENT_IMPORT,
  },
};

export const routes = {
  downForMaintenance,
  login,
  passwordReset,
  terms,
  privacy,
  acceptInvitation,
  root,
  // todo: hide dashboard
  claimReportList,
  reportablesList,
  patientClaimReportList,
  patientReportableList,
  dashboard,
  claimsReportingList,
  editClaim,
  patientUpload,
  insuranceUpload,
  providersUpload,
  editProfile,
  changePassword,
  accountsList,
  accountCreate,
  accountEdit,
  accountUsers,
  accountUserCreate,
  accountUserEdit,
  patientsList,
  accountPatientsList,
  patientOrdersList,
  patientClaimsReportingList,
  patientOrdersListShowPopup,
  patientAssessmentListShowPopup,
  patientOrderCreate,
  patientAssessmentCreate,
  patientOrderEdit,
  ordersList,
  allergiEndResources,
  resourcesList,
  patientResourcesList,
  assessmentsList,
  billingList,
  patientAssessmentReport,
  patientAssessmentsList,
  patientBillingList,

  // add all paths above these
  notFoundProtected,
};

export const defaultRoutes = [
  // todo: hide dashboard
  dashboard,
  accountsList,
  patientsList,
];
