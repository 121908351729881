export default {
  en: {
    general: {
      searchAll: 'search all',
      pleaseContactUs: 'Please contact us for the additional assistance.',
      editThisAccount: 'Edit This Account',
    },
    btn: {
      addNew: 'ADD NEW',
      Download: 'Download',
      cancelLastChanges: 'cancel last changes',
      confirmBtn: 'Confirm',
      acceptResults: 'Accept Results',
      verifyAndGenerate: 'Verify and Generate',
      addAssessment: 'Add Assessment',
      enableClaim: 'Enable Claim',
      clearFilter: 'Clear Filter',
      applyChanges: 'Apply Changes',
      login: 'Login',
      proceed: 'Proceed',
      forgotPassword: 'Forgot Password?',
      cancel: 'Cancel',
      remove: 'Remove',
      save: 'Save',
      modifyDemographics: 'Modify Demographics',
      submit: 'Submit',
      sendCode: 'Send Code',
      confirmAndContinue: 'Confirm and Continue',
      create: 'Create',
      addPatient: 'Add Patient',
      editPatient: 'Edit Patient',
      addAccount: 'Add Account',
      addOrder: 'Add Order',
      createAccount: 'Create Account',
      generateReport: 'Generate Report',
      createPatient: 'Create Patient',
      createOrder: 'Create Order',
      contactSupport: 'Contact Support',
      copyToClipboard: 'Copy This Section',
      createAssessment: 'Create assessment',
      createResource: 'Add Resource',
      select: 'Select',
      readMore: 'Read More',
      back: 'Back',
      selected: 'Selected',
      current: 'Current',
      setPasswordLogIn: 'Set Password & Log In',
      updatePassword: 'Update password',
      export: 'Export',
      close: 'Close',
      openAssessmentIntoNewTab: 'Open Assessment In New Browser Tab',
      acceptContinue: 'Accept & Continue',
      ok: 'Ok',
      logOut: 'Log Out',
      stayLoggedIn: 'Stay Logged In',
      resendInvitation: 'Resend Invitation',
      goToListOfPatients: 'Go to list of patients',
      orders: 'Orders',
      assessments: 'Assessments',
      resource: 'Resources',
      billing: 'Billing',
      billingReports: 'Billing Reports',
      claimsReporting: 'Claims',
      claimReport: 'Claim Report',
      reportable: 'Reportable',
      takeInClinic: 'Take In-Clinic',
      sendAnInvite: 'Send an Invite',
      closeAll: 'Close All',
      expandAll: 'Expand All',
      default: 'Default',
      applyFilter: 'Apply Filter',
      downloadPDF: 'Download Outstanding Claim Report',
      downloadCSV: 'Download CSV',
      downloadZIP: 'Download ZIP',
      download: 'Download',
      refresh: 'Refresh',
      сlearAllFilters: 'Clear All Filters',
    },
    nav: {
      resetPassword: 'Reset Password',
      logOut: 'Log Out',
      build: 'Build',
      env: 'ENV',
      dev: 'DEV',
      testing: 'TESTING',
      qhsLabs: 'QHS Lab',
      privacy: 'Privacy',
      terms: 'Terms',
      hippa_baa: 'HIPPA BAA',
    },
    titles: {
      resource: 'resource',
      allowedAccounts: 'Allowed Accounts',
      cpts: 'CPTs',
      cpt: 'CPT',
      accountCptCodes: 'Account CPT Codes',
      preview: 'preview',
      noAccountSelected: 'No account selected',
      displayedColumns: 'Displayed columns',
      assessmentInProgress: 'Assessment In Progress',
      someDataNotAvailable: 'Some data may not be available yet',
      claimReports: 'Claim Reports',
      reportables: 'Reportables',
      reported: 'Reported',
      downloadReport: 'Download Report',
      accepted: 'Accepted',
      items: 'items',
      notFound: 'Not Found',
      dashboard: 'Dashboard',
      claimsReporting: 'Claims',
      claims: 'claims',
      editClaim: 'Edit Claim',
      patientUpload: 'Patient Upload',
      insuranceUpload: 'Insurance Upload',
      providersUpload: 'Providers Upload',
      enableClaim: 'Enable Claim for This {{source}}',
      enableClaimSubtitle:
        'A claim will be enable for this {{source}}. To remove the claim (undo this operation), mark the claim as Not Billable from the Claims area.',
      patientUploadForAccount: 'Patient Upload for {{accountName}}',
      insuranceUploadForAccount: 'Insurance Upload for {{accountName}}',
      providersUploadForAccount: 'Providers Upload for {{accountName}}',
      editProfile: 'Edit Profile',
      changePassword: 'Change Password',
      accounts: 'Accounts',
      providers: 'Providers',
      account: 'Account',
      createAccount: 'Create Account',
      editAccount: 'Edit Account',
      removeAccount: 'Remove Account',
      editUsers: 'Edit Users',
      users: 'Users',
      user: 'User',
      viewBillingReport: 'View Billing Report',
      addNewPatient: 'Add Patient',
      createNewAssessment: 'Create New Assessment',
      createNewResource: 'Create New Resource',
      accountUsers: 'Account Users',
      manageUsers: 'Manage Users',
      administerClaims: 'Administer Claims',
      administerClaimReports: 'Administer Claim Reports',
      administerReportable: 'Administer Reportable',
      receiveClaimsEmails: 'Receive Claims Emails',
      receiveSurveysEmails: 'Receive Account Status Emails',
      imminentRiskEmails: 'Imminent Risk Emails',
      createUser: 'Create User',
      removeUser: 'Remove User',
      editUser: 'Edit User',
      patients: 'Patients',
      patient: 'Patient',
      editPatient: 'Edit Patient',
      selectRangeDate: 'Select Range Date',
      downloadClaim: 'Download Outstanding Claim Report',
      removePatient: 'Remove Patient',
      createPatient: 'Create Patient',
      editOrder: 'Edit Order',
      removeOrder: 'Remove Order',
      reorder: 'Reorder',
      createOrder: 'Create Order',
      createAssessment: 'Create Assessment',
      orders: 'Orders',
      order: 'Order',
      assessmentCreated: 'Assessment Created',
      resourceCreated: 'Resource Created',
      verifyInvitationDetails: 'Verify Invitation Details',
      assessments: 'Assessments',
      reports: 'Reports',
      report: 'Report',
      billing: 'Billing',
      billingReports: 'Billing Reports',
      allergiEndResources: 'AllergiEnd Resources List',
      resources: 'Resources',
      editAssessment: 'Edit Assessment',
      editBill: 'Edit Bill',
      removeBill: 'Remove Bill',
      patientDashboard: 'Patient Dashboard',
      removeAssessment: 'Remove Assessment',
      assessmentAdded: 'New Assessment Added',
      patientAdded: 'New Patient Added',
      invitationSent: 'Invitation Sent',
      loading: 'Loading...',
      pleaseWait: 'Please wait',
      nothingFound: 'Nothing found matching your request.',
      resendInvitation: 'Resend Invitation',
      generateClaim: 'Generate Claim',
      viewAssessmentLink: 'View Assessment Link',
      generateProgramClaim: 'Generate Program Claim',
      downloadProgram: 'Download Program',
      downloadImmunotherapyReport: 'Download Immunotherapy Report',
      reportNavigation: 'report navigation',
      linkWillBeProvided:
        'A link will be provide after the assessment is created.',
      downForMaintenance:
        'Down for maintenance.',
      downForMaintenanceSubtitleFirst:
        "We're currently working on some updates to our platform and will be down in the meantime.",
      downForMaintenanceSubtitleSecond:
        "Thank you for your patience.",
      openThisPage:
        'Open this page on the appropriate device and allow the patient to complete the assessment. Once completed, you will receive next steps for processing.',
    },
    forms: {
      ID: 'ID',
      address: 'Address',
      selectAll: 'Select all',
      allowedResources: 'Allowed Resources',
      allowedSurveys: 'Allowed Surveys',
      addresses: 'Addresses',
      chooseAnAccount: 'Choose an account',
      encounterStatus: 'Encounter Status',
      patientLanguage: 'Patient Language',
      dos: 'Date of Service',
      insurance: 'Insurance',
      cptInfo: 'CPT Info',
      billingStatus: 'Billing Status',
      location: 'Location',
      appointmentProvider: 'Appointment Provider',
      checkDate: 'Check Date',
      paymentDate: 'Payment Date',
      paymentMethod: 'Payment Method',
      prType: 'PR Type',
      claimGeneratedTimestamp: 'Claim Created',
      createdBy: 'Created By',
      lastModifiedTimestamp: 'Modified Date',
      modifiedBy: 'Modified By',
      cptCode: 'CPT Code',
      price: 'Price',
      accountName: 'Account Name',
      deliverInvitationBy: 'Deliver Invitation By',
      orderType: 'Order Type',
      email: 'Email',
      password: 'Password',
      firstName: 'First Name',
      lastName: 'Last Name',
      features: 'Features',
      claimReportType: 'Claim Report Type',
      phone: 'Phone',
      confirmPassword: 'Confirm Password',
      confirmCode: 'Confirm Code',
      hasEmailSubscription: 'Email Subscription',
      hasEmailSubscriptionDescription: 'The patient has given written or verbal consent to receive Email messages.',
      smsOptIn: 'SMS Opt-In',
      smsOptInDescription:
        'This patient has given written or verbal consent to receiving SMS messages.',
      distributionSponsor: 'Distribution Sponsor',
      updatePatientRecord: 'Update patient record',
      updatePatientRecordEmail: 'Update patient record with this email',
      updatePatientRecordPhone: 'Update patient record with this phone',
      businessName: 'Business Name',
      tradeName: 'Trade Name',
      sameAsBusinessName: 'Same as Business Name',
      fax: 'Fax',
      contactName: 'Contact Name',
      headProviderName: 'Head Provider Name',
      customId: 'Custom ID',
      name: 'Name',
      link: 'Link',
      streetAddress: 'Street Address',
      suite: 'Suite',
      city: 'City',
      state: 'State',
      zipCode: 'Zip Code',
      basicInfo: 'Basic info',
      organizationAddress: 'Organization Address',
      overrideDefaultMessage: 'Override Default Message',
      useDefaultMessage: 'Use Default Message',
      survey: 'Survey',
      resource: 'Resource',
      english: 'English',
      spanish: 'Spanish',
      invitationEmail: 'Invitation Email',
      invitationSMS: 'Invitation SMS',
      reminderEmail: 'Reminder Email',
      reminderSMS: 'Reminder SMS',
      completionEmail: 'Completion Email',
      completionSMS: 'Completion SMS',
      editAccountScreen: 'Edit Account screen',
      scheduledActivities: 'Scheduled Activities',
      ianaTimeZone: 'Time Zone',
      scheduleDeliverTime: 'Scheduled Delivery Time',
      scheduleDeliverDate: 'Scheduled Delivery Date',
      scheduleDeliverData: 'When do you want to sent it?',
      shippingAddress: 'Shipping Address',
      accountType: 'Account Type',
      useSameAddress: 'Use the same as organization address',
      enableOrders: 'Enable Orders',
      enableAllergiEndResources: 'Enable AllergiEnd Resources List',
      enableAssessments: 'Enable Assessments',
      enableResources: 'Enable Resources',
      enableClaimsManagement: 'Enable Claims Management',
      disableOrdersAutomations: 'Disable Orders Automations',
      otherAddresses: 'Other Addresses',
      addAddress: 'Add Address',
      manageCpts: 'Manage CPT codes',
      removeAddress: 'Remove Address',
      userDetails: 'User Details',
      userRoleDetails: 'User Role Details',
      accountAccess: 'Account Access',
      selectAllFilteredItems: 'Select All {{filtered}}Items',
      unselectAllFilteredItems: 'Unselect All {{filtered}}Items',
      accountAccessNumber: 'Number of account{{end}} with access granted: {{number}}',
      accountAccessTotalNumber: 'Total number of selected account{{end}}: {{number}}',
      cptsTotalNumber: 'CPT Codes: {{number}}',
      selectAllAccounts: 'Select all accounts',
      modifyAccountAccessList: 'Modify Child Account Access List',
      title: 'Title',
      npiNumber: 'NPI Number',
      provider: 'Provider',
      assistant: 'Assistant',
      role: 'Role',
      mrnNumber: 'MRN #',
      dateOfBirth: 'Date of Birth',
      scheduleDate: 'Schedule Date',
      gender: 'Gender',
      genderAtBirth: 'Gender at Birth',
      allergies: 'Allergies',
      drugAllergies: 'Drug Allergies',
      noDrugAllergies: 'No Drug Allergies',
      drugList: 'Drug List',
      yes: 'Yes',
      no: 'No',
      unknown: 'Unknown',
      servesDate: 'Serves Date',
      patientName: 'Patient Name',
      assistantType: 'Assistant Type',
      datePaid: 'Date Paid',
      ctpNumber: 'CPT Code',
      allowed: 'Allowed',
      notAllowed: 'Not Allowed',
      all: 'All',
      notes: 'Notes',
      paid: 'Paid',
      pr: 'PR',
      female: 'Female',
      male: 'Male',
      comments: 'Comments',
      slit: 'Slit',
      scit: 'Scit',
      ctpInfo: 'CTP Info',
      orderCategory: 'Order Category',
      sendNow: 'Send Now',
      scheduleForLater: 'Schedule for Later',
      allergens: 'Allergens',
      patient: 'Patient',
      maintenance: 'Maintenance',
      lowDose: 'Low Dose',
      quantity: 'Quantity',
      invitationWillBeSent:
        'Invitation will be sent to the patient’s contact information provided above.',
      invitationWillBeResent:
        'Another invitation will be sent to the email/phone specified above',
      invitationWillBeSentSubtitle:
        'An invitation will be sent to the email/phone specified above',
      acceptInvitation:
        'Your QHS account is almost ready, you\'ll just need to configure a password.',
      resetPasswordStep1:
        'Enter the email address you use to log in. We\'ll send you a confirmation code to verify your identity.',
      resetPasswordStep2:
        'You should receive an email from us within a few moments with a confirmation code. Enter that code below to continue',
      resetPasswordStep3:
        'Thanks {{name}}! The code was confirmed. Now set your new password below',
      appointmentTimestamp: 'Appointment Date',
      selectAppointmentProvider: 'Select Appointment Provider',
      appointmentDate: 'Appointment Date',
      documentDate: 'Document Date',
      patientSeen: 'Patient Seen?',
      documentUploaded: 'Document Uploaded',
      modifier: 'Modifier',
      icd: 'ICD',
      id: 'ID',
      check: 'Check#',
      claimStatus: 'Claim Status',
      cptStatus: 'CPT Status',
      appointmentTime: 'Appointment Time',
    },
    tables: {
      name: 'Name',
      hasClaim: 'Has Claim',
      hasProgram: 'Has Program',
      price: 'Price',
      surveyTags: 'Survey Tags',
      entityName: 'Entity Name',
      entityType: 'Entity Type',
      preFilter: 'Pre-Filter',
      preFilterHint: 'Select “All” to see all options for this column. To pre-filter based on other column filters you’ve configured, choose “Pre-Filter”.',
      selectedOptions: 'Selected Options:',
      availableOptions: 'Available Options',
      allOptions: 'All Options',
      uncheckAllOptions: 'Uncheck all options',
      noAvailableOptions: 'No Available Options.',
      noSelectedOptions: 'No Selected Options.',
      addSomeTextToSearch: 'Start typing the name in the search to see the options.',
      cptCount: 'CPT Count',
      cptPaid: 'Paid CPTs Count',
      NotBillable: 'Not Billable',
      TotalBilledAmount: 'Total Billed Amount',
      TotalPaidAmount: 'Total Paid Amount',
      TotalPRAmount: 'Total PR Amount',
      TotalDeniedAmount: 'Total Denied Amount',
      TotalOutstandingAmount: 'Total Outstanding Amount',
      TotalPartiallyPaidAmount: 'Total Partially Paid Amount',
      TotalNotBillableAmount: 'Total Not Billable Amount',
      TotalNotBilledAmount: 'Total Not Billed Amount',
      assessmentResource: 'Assessment/Resource',
      entityCompletedTimestamp: 'Completed Date',
      customId: 'Custom ID',
      claimReportGeneratedTimestamp: 'Claim Report Generated Date',
      reportableGeneratedTimestamp: 'Reportable Generated Date',
      totalAmount: 'Total Amount',
      totalCount: 'Total Count',
      uniqueAccounts: 'Unique Accounts',
      totalPaidAmount: 'Total Reported Amount',
      totalUnpaidAmount: 'Total Reportable Amount',
      totalCPTs: 'Total CPTs',
      generateClaimReport: 'Generate Claim Report',
      accessGranted: 'Access Granted',
      mrnNumber: 'Patient MRN',
      createdBy: 'Created By',
      type: 'Type',
      manuallyCreated: 'Manually Created',
      integration: 'Integration',
      automated: 'Automated',
      automation: 'Automation',
      invitedTimestamp: 'Invitation Date',
      appointmentTimestamp: 'Appointment Date',
      engagedTimestamp: 'Engaged Date',
      firstName: 'First Name',
      lastName: 'Last Name',
      address: 'Address',
      more: 'More',
      roles: 'Roles',
      account: 'Account',
      accountName: 'Account Name',
      numberOfClaimCPTLineItems: '# of Claim CPT Line Items',
      assessment: 'Assessment',
      patientName: 'Patient Name',
      patientFirstName: 'Patient First Name',
      patientLastName: 'Patient Last Name',
      orderTimestamp: 'Order Timestamp',
      resource: 'Resource',
      patientDOB: 'Patient DOB',
      orderType: 'Order Type',
      orderFormula: 'Order Formula',
      orderDate: 'Order Date',
      customTags: 'Custom Tags',
      helpRequested: 'Help Requested',
      reviewed: 'Reviewed',
      claimGeneratedTimestamp: 'Claim Generated Date',
      entityOrderTimestamp: 'Order Date',
      cptCode: 'CPT Code',
      allowed: 'Allowed',
      pr: 'PR',
      modifier: 'Modifier',
      icd: 'ICD',
      dos: 'DOS',
      prType: 'PR Type',
      cptStatus: 'CPT Status',
      reportStatus: 'Report Status',
      expectedDeliveryDate: 'Expected Delivery Date',
      status: 'Status',
      encounterStatus: 'Encounter Status',
      paymentDate: 'Payment Date',
      lastModifiedTimestamp: 'Modified Date',
      modifiedBy: 'Modified By',
      billingStatus: 'Billing Status',
      claimStatus: 'Claim Status',
      appointmentDate: 'Appointment Date',
      claimReportStatus: 'Claim Report Status',
      filter: 'filter',
      placeholder: 'placeholder',
      date: 'Date',
      all: 'All',
      pending: 'Pending',
      reportable: 'Reportable',
      icdIssue: 'ICD Issue',
      insuranceIssue: 'Insurance Issue',
      appointmentCancelled: 'Appt. Cancelled',
      reported: 'Reported',
      onHold: 'On Hold',
      processing: 'Processing',
      sending: 'Sending',
      delayed: 'Delayed',
      shipped: 'Shipped',
      putOnHold: 'Put On Hold',
      revertToPending: 'Revert To Pending',
      processManually: 'Process Manually',
      cancel: 'Cancel',
      sendToPharmacy: 'Send To Pharmacy',
      delay: 'Delay',
      ship: 'Ship',
      canceled: 'Canceled',
      compounding: 'Compounding',
      dateOfBirth: 'Date of Birth',
      mmddyyyy: 'MM/DD/YYYY',
      mm: 'MM',
      dd: 'DD',
      yyyy: 'YYYY',
      claimReportHistory: 'Claim Report history',
      reportableHistory: 'Reportable history',
      ordersHistory: 'Orders history',
      claimsHistory: 'Claims history',
      assessmentsHistory: 'Assessments history',
      resourcesHistory: 'Resources history',
      assessmentsAlerts: 'Assessment Alerts',
      healthSeekingBehavior: 'Help Seeking Behavior',
      items: '{{totalCount}} Items',
      searchCustomers: 'Search Customers',
      pendingReview: 'Pending Review',
      eTransfer: 'E-Transfer',
      invited: 'Invited',
      inProgress: 'In Progress',
      scheduled: 'Scheduled',
      engaged: 'Engaged',
      completed: 'Completed',
      billingHistory: 'Billing history',
      completionRate: 'Completion Rate',
      assessmentName: 'Assessment name',
      procedureName: 'Procedure Name',
      completedTimestamp: 'Date Completed',
      orderedTimestamp: 'Date Ordered',
      providerFirstName: 'Provider First Name',
      providerLastName: 'Provider Last Name',
      reviewedTimestamp: 'Date Reviewed',
      draft: 'Draft',
      notCreated: 'Not Created',
      billed: 'Billed',
      inProcess: 'In Process',
      paid: 'Paid',
      denied: 'Denied',
      resubmitted: 'Resubmitted',
      notBillable: 'Not Billable',
      isp: 'ISP',
      license: 'License',
      ordersOnly: 'Orders Only',
      trial: 'Trial',
      demo: 'Demo',
      other: 'Other',
      inactive: 'Inactive',
      Billed: 'Billed',
      NotBilled: 'Not Billed',
      Paid: 'Paid',
      PR: 'PR',
      Denied: 'Denied',
      Outstanding: 'Outstanding',
      PartiallyPaid: 'Partially Paid',
    },
    placeholders: {
      noColumnsSelected: 'No columns selected',
      noItemsFound: 'No {{item}} Found',
      noFilteredItemsFound: '{{item}} have not been authorized. \n' +
        'Contact QHSLab at Ph 800-393-8817 to enable {{item}} for this Account.',
      reviseFiltersOrCreateYourFirstItem:
        'Revise filters or create your first {{item}}.',
      selectAtLeastOneColumn:
        'Select at least one column to display the table.',
      pleaseChangeSearchText: 'Please change the search text',
      noCPTCodes: 'No CPT Codes',
      addCPTCodes: 'Add a CPT code with the {{item}} button below',
    },
    validationErrors: {
      required: 'required field',
      matchPasswords: 'passwords must match',
      invalid: 'invalid {{name}}',
      minError: '{{name}} should be at least {{length}} characters long',
      maxError: '{{name}} should be a maximum of {{length}} characters long',
    },
    errors: {
      unknownError:
        'Your request can not be completed due to an unknown error.',
      userNotFound: 'We could not find an account for the email you provided.',
      accountIsDisabled: 'Account is disabled. \nPlease contact support at 1-800-393-8817.',
      credentialsInvalid: 'The email or password you entered was not valid.',
      validationError:
        'Some input appears to be invalid. Fix any issues indicated in red, and try again.',
      pleaseEnterDescription:
        'Please enter a description of the issue you are having. Do not remove the content under the line below',
      supportRequest: 'QHS Support Request',
      invalidData: 'Invalid Data',
      noProvidersOnThisAccount:
        'No providers on this account. At least one provider is required to create an order.',
      noLongerValid: 'This invitation is no longer valid.',
      accountOrUser: 'Account or user not found.',
      orderCouldNotFind:
        'Could not find the specified account or {{name}}. Refresh and try again.',
      orderCantBeRemoved:
        'The order cannot be removed, as it no longer has a status of Pending.',
      orderStatusMultiplyValidation: `The status you are trying to change for one or more {{name}}s is not
        allowed. Check the {{name}}s and try again.`,
      orderStatusSingleValidation:
        'The status you selected is not allowed based on the current status of the {{name}}.',
      patientCouldNotFind:
        'Could not find the specified account or patient. Refresh and try again.',
      patientCantBeRemoved: 'The patient {{name}} cannot be removed.',
      userCouldNotFind:
        'Could not find the specified user. Refresh and try again.',
      userCantBeRemoved:
        'The user profile for {{name}} cannot be removed from this account.',
      accountCouldNotFind:
        'Could not find the specified account. Refresh and try again.',
      accountCantBeRemoved: 'The account {{name}} cannot be removed.',
      userProfileNotFound:
        'A user profile was not found for the email specified.',
      verificationCodeInvalid:
        'The verification code is invalid. Please try again or send a new verification code.',
      tooManyInvitations:
        'Too many invitations were sent too quickly. Wait a few minutes and try again.',
      invitationCannotBeSent:
        'Invite was sent too quickly for this survey. Try again in a minute or two.',
      invitationMessageWasJustSent:
        'Invitation message was just sent. Please try again in a few moments.',
      invitationsWereNotOriginallySent:
        'Invitations were not originally sent for this survey, so an invitation resend is not allowed.',
    },
    notifications: {
      assessmentAccepted: 'Assessment accepted successfully',
      autoLogout: 'You have been automatically logged out due to inactivity',
      logout401: 'Your QHSLab session has expired. Log in again to resume.',
      profileUpdated: 'Your profile updated successfully.',
      accountCreated: 'Account "{{name}}" created successfully.',
      accountEdited: 'Account "{{name}}" edited successfully.',
      claimEdited: 'Claim edited successfully.',
      accountRemoved: 'The account {{name}} has been removed.',
      userCreated: 'User "{{name}}" created successfully.',
      userEdited: 'User "{{name}}" edited successfully.',
      userRemoved: 'The user profile for {{name}} has been removed', // from {{accountName}}.',
      patientCreated: 'Patient "{{name}}" created successfully.',
      notPermissionForEditingClaim: 'You do not have permission to edit claim.',
      surveyURLCopied: 'Assessment URL has been copied to your clipboard',
      patientEdited: 'Patient "{{name}}" edited successfully.',
      claimEnabled: 'The claim has been successfully enabled.',
      patientRemoved: 'The patient "{{name}}" has been removed.',
      pdfSucceededSaved: 'The pdf file downloaded successfully.',
      zipSucceededSaved: 'The ZIP file downloaded successfully.',
      orderCreated: 'Order for "{{name}}" created successfully.',
      resourceCreated: 'Resource for "{{name}}" created successfully.',
      orderEdited: 'Order for "{{name}}" edited successfully.',
      orderRemoved: 'The order has been removed.',
      orderStatusChanged: '"{{name}}" status(es) changed successfully.',
      verifyCodeHasBeenSent: 'Verify code has been sent.',
      passwordHasBeenChanged: 'Password has been changed.',
      addProvider:
        'Add at least one provider to the account before creating orders.',
      youHaveBeenSwitched: `You've been automatically switched to account {{name}}`,
      welcomeToQHS: 'Your password has been set. Welcome to QHS!',
      emailInvitation: 'Another email invitation has been sent.',
      assessmentCreated: 'Assessment Created',
      assessmentMustBeCompleted:
        'The patient must complete the assessment before viewing the report',
      invitationResent: 'Invitation resent successfully',
      copyToClipboard: 'The text was successfully copied to the clipboard',
      notPermissionForDeletingOrder:
        'Oops! we’re sorry, but your ability to Delete Orders has been suspended or not yet enabled in your Account. Please contact customer service at 1-800-393-8817 or email us at services@qhslab.com to request this feature be activated.',
      notPermissionForEditingOrder:
        'Oops! we’re sorry, but your ability to Edit Orders has been suspended or not yet enabled in your Account. Please contact customer service at 1-800-393-8817 or email us at services@qhslab.com to request this feature be activated.',
      notPermissionForCreatingOrder:
        'Oops! we’re sorry, but your ability to Create Orders has been suspended or not yet enabled in your Account. Please contact customer service at 1-800-393-8817 or email us at services@qhslab.com to request this feature be activated.',
      notPermissionForChangeClaimReportStatus:
        'Oops! we’re sorry, but your ability to Change Claim Report Status has been suspended or not yet enabled in your Account. Please contact customer service at 1-800-393-8817 or email us at services@qhslab.com to request this feature be activated.',
      notPermissionForCreatingAssessment:
        'Oops! we’re sorry, but your ability to Create Assessments has been suspended or not yet enabled in your Account. Please contact customer service at 1-800-393-8817 or email us at services@qhslab.com to request this feature be activated.',
      hasDrugAllergies:
        'This patient’s currently has unknown drug allergies. Update the patient record to create orders.',
    },
    dialogs: {
      backTo: 'back to {{title}}',
      confirmRemove: 'Confirm {{name}} Remove',
      confirmStatusChange: 'Confirm Status Change',
      generateClaimsReport: 'Generate Claims Report',
      additionalOptions: 'Additional Options',
      downloadXlsxFile: 'Download XLSX File (optional)',
      confirmResendInvitation: 'Confirm Resend Invitation',
      reportHasBeenGenerated: 'Report has been generated. You can view/download it from the Claim Reports screen.',
      generateClaimsReportDescription1: 'A claims report will be generated for the items you selected. Here is a summary of the claim items. To generate the report, verify everything looks correct and select ',
      generateClaimsReportDescription2: 'Verify and Generate ',
      generateClaimsReportDescription3: 'below.',
      statusDescriptionForOneOption:
        'Change status to "{{status}}" for the current row?',
      statusDescription:
        'Change status to "{{status}}" for all selected rows or only the current row?',
      prescriberDeclaration: 'Prescriber Declaration',
      bySavingThisPrescription: `By saving this prescription, I state that:`,
      requirementA: `a) I am or was treating this patient for the indicated diagnosis on the
      effective date of this order;`,
      requirementB: `b) This order accurately reflects this patient’s diagnosis and condition
      and is substantiated by my medical records;`,
      requirementC: `c) I will make the original signed copy of this document a part of this
      patient’s medical records and make it available to Medicare, Medicaid and other insurers
      or any authorized agent if requested.`,
      prescribingPhysician:
        'prescribing physician has approved this order to be sent to the pharmacy',
      hipaaAgreement: 'HIPAA Business Associate Agreement',
      hipaaAgreementFor:
        'HIPAA Business Associate Agreement for {{accountName}}',
      privacyFor: 'Privacy for {{accountName}}',
      hippa_baa_For: 'HIPPA BAA for {{accountName}}',
      termsFor: 'Terms for {{accountName}}',
      scrollToTheBottom: 'Scroll to the bottom to accept agreement',
      readAndAccept: 'I have read and accept the agreement',
      administratorMustAccept: `An administrator must accept the HIPPA Business Associate
      Agreement before the account can be accessed.`,
      sessionTimeout: 'Session Timeout',
      inactivityWarning: `You're being timed out due to inactivity. Please choose to stay
      signed in or to logout. Otherwise, you will be logged out automatically.`,
      createAssessmentDescription:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh.',
      updateRequired: 'Update Required',
      chooseProviders: 'Choose Providers',
      updateVersionSubtitle:
        'There is a newer version of this dashboard available. To be sure you don’t run into any problems, click Update below to load the new version.',
      newVersion: 'Update to Version ',
      skipNow: 'Skip for now',
    },
    scit: {
      buildUp46: '46 Dose/ Unit Build Up',
      buildUp50: '50 Dose/Unit Build-up',
      stage1: '10 Dose/ Unit Stage 1',
      stage2: '10 Dose/ Unit Stage 2',
      stage3: '10 Dose/ Unit Stage 3',
      stage4: '10 Dose/ Unit Stage 4',
      scitMaintenance: '12 Dose/ Unit SCIT Maintenance',
    },
    slit: {
      oneMonthLowDose: '1 Month Low Dose',
      oneMonthSLITMaintenance: '1 Month SLIT Maintenance',
    },
    allergens: {
      grasses: 'Grasses',
      grassesGrassMix:
        'Grass Mix (Timothy, Orchard, June, Red Top, Meadow Fescue, Perennial Rye, Sweet Venal)',
      grassesBermuda: 'Bermuda',
      grassesJohnson: 'Johnson',
      weeds: 'Weeds',
      weedsRagweed: 'Ragweed (Short ragweed, Giant/Tall Ragweed, Sagebrush)',
      weedsMugwort: 'Mugwort',
      weedsCocklbur: 'Cocklbur',
      weedsRoughMarshelder: 'Rough Marshelder',
      weedsPigweed: 'Pigweed (Rough Pigweed, Carelessweed, Western Water Hemp)',
      weedsDockSorrell: 'Dock Sorrell (Yellow Dock, Sheep Sorrel)',
      weedsChenopod: 'Chenopod (Kochia, Russian Thistle, Lambs Quarters)',
      weedsEnglishPlaintain: 'English Plantain',
      trees: 'Trees',
      treesBirch: 'Birch (Birch, Alder, Hazel)',
      treesCypress: 'Cypress (Juniper, Cedar, Cypress)',
      treesOlive: 'Olive (Olive, Ash, Privet)',
      treesBeechOak: 'Beech-Oak (Beech, Oak, Chestnut)',
      treesPecanHickory: 'Pecan-Hickory',
      treesPolarAspenCottonwood: 'Poplar-Aspen-Cottonwood',
      treesMapleElder: 'Maple-Elder',
      treesSycamore: 'Sycamore',
      treesElm: 'Elm',
      cat: 'Cat',
      dog: 'Dog',
      dustMites: 'Dust Mites',
      cockroach: 'Cockroach',
      mold: 'Mold',
    },
    formulas: {
      outdoor: 'Outdoor',
      indoor2: 'Indoor 2',
      indoor3: 'Indoor 3',
      indoor4: 'Indoor 4',
      mold: 'Mold',
      environment: 'Environment',
    },
    ordersCsv: {
      organization: 'Organization',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'DOB',
      gender: 'Gender',
      allergies: 'Allergies',
      patientPhone: 'Patient Phone',
      patientAddress: 'Patient Address',
      clinician: 'Clinician',
      npi: 'NPI',
      shipTo: 'Ship To',
      category: 'Category',
      type: 'Type',
      formula: 'Formula',
      qty: 'Qty',
      geography: 'Geography',
      date: 'Date',
      comments: 'Comments',
    },
    allergyBaselineAssessmentReport: {
      title: 'Allergy Baseline Assessment Report',
      firstPart: `The QHSLab ‘Allergy Assessment’ is a proprietary digital medicine tool developed to assist clinicians identify and deal with common allergic conditions, allergen triggers, symptom severity, medication use and frequency and health habits. The Allergy Assessment agrees with the definition for using CPT code 96160, “Administration of patient-focused health risk assessment instrument (e.g., health hazard appraisal) with scoring and documentation, per standardized instrument.`,
      requirementsFirst: 'The requirements to report 96160 are as follows:',
      requirementsSecond:
        'Practice expense is incurred to administer the instrument (such as nurse time or for purchase of the screen).\n' +
        'The instrument is standardized.\n' +
        'The instrument is scorable.\n' +
        'The results are documented in the medical record.',
      requirementsThird:
        'The term “standardized” means previously validated tests that are administered and scored in a consistent or “standard” manner.',
      requirementsForth:
        'What constitutes “scoring.” This is an important distinction between a true health risk assessment and history gathering forms. The instrument, at a minimum, should provide a threshold result or pertinent yes/no responses that would indicate a patient’s risk for an adverse health outcome or risk for further exacerbation of a current, typically chronic, medical condition.',
      QHSLabAllergyAssessmentTitle: 'QHSLab Allergy Assessment',
      QHSLabAllergyAssessmentText:
        'The Allergy Assessment is a proprietary digital medicine application that combines several validated and standardized allergy and asthma-related instruments described below. This assessment includes patient responses and scoring related to their allergy history, recent allergy and asthma symptoms, environmental impact and severity of these symptoms, allergy and asthma medication use, and lifestyle behaviors. The Allergy Assessment was developed to help physicians and other healthcare providers in their routine evaluation of patients suspected to have allergies and comorbid asthma. "Digital Medicine" is a term that relates to computerized applications that are directed by physicians and other healthcare providers. This assessment is not meant to be diagnostic or therapeutic, but to be used as an adjunct to clinical decision making. \n',
      respiratoryAllergyPredictionTestTitle:
        'Respiratory Allergy Prediction Test',
      respiratoryAllergyPredictionTestText:
        'The Respiratory Allergy Prediction Test is a validated and straightforward four item assessment. This is designed to confirm the clinical suspicion of respiratory allergy and the medical necessity of performing allergy testing by its high predictive value. Answering yes to the following three questions: 1) occurrence of symptoms in the springtime, 2) to have or have had a cough and shortness of breath, and 3) to frequent use of nasal sprays, provides a high probability (85%) of a respiratory allergy diagnosis. It also suggests a real need for allergy testing to confirm the allergen trigger. If a patient answered yes to the questions just described. In that case, the physicians digital interpretation report will indicate the patient is highly likely to test positive to an allergy blood or skin test for confirmation of the environmental allergen triggers (Galimberti et al., 2015). ',
      generalAllergyHistoryTitle: 'General Allergy History',
      generalAllergyHistoryText:
        'A patient\'s knowledge of their various health conditions, or "health literacy," has been shown to benefit their approach to self-management and overall quality of life. Suppose a patient answers that they do not know if they have a health condition such as Allergic Rhinitis, Asthma, Allergic Conjunctivitis, or Allergic Urticaria. This situation could provide an educational opportunity to explain the condition, determine if the patient is suffering from the condition, and explain proper management of the condition. If a patient answers that they have the condition and treated in the past, this will provide insight into their expectation of the current treatment to be proposed. Both of these responses are opportunities to reinforce evidence-based approaches to allergy management.',
      patientReportedAllergySymptomsTitle: 'Patient Reported Allergy Symptoms',
      patientReportedAllergySymptomsText:
        'Allergens that cause symptoms can be broadly categorized as predominantly outdoor, indoor, or food-related. Different outdoor allergens pollinate at various times during the year. Trees produce pollen in the early to mid-springtime, grass pollination occurs later in spring through summer, and weeds pollinate in the fall season. Outdoor mold spores can also affect allergy sufferers year round when conditions suit fungi or mold growth, such as after the winter frost melts in early spring or after heavy rain periods followed by warmer weather conditions. Perennial allergens, such as dust mites, animal dander, insects, and mold allergens, which are likely to be found indoors in areas with high moisture or humidity conditions are very prevalent year round allergy symptom triggers. A patient\'s responses to questions regarding symptoms, occurrence, and environment help physicians determine the likely allergen to be affecting the patient, to be confirmed through a skin or blood test, and also helps the physician prescribe or modify medications.',
      sinoNasalOutcomeTestTitle: 'Sino-Nasal Outcome Test-22 (SNOT-22)',
      sinoNasalOutcomeTestText1:
        'The Sino-Nasal Outcome Test-22 (SNOT-22) is a commonly utilized disease-specific questionnaire involving 22 symptoms that combine rhinologic issues with general health issues. The SNOT-22 has been validated and has been proven easy to use throughout the world in clinical practice and research. Physicians can use this test to facilitate routine clinical practice to highlight chronic rhinosinusitis\'s impact on patients\' quality of life. This test is also frequently used to measure the outcome of surgical intervention and specific allergen immunotherapy treatment (Hopkins et al., 2009).',
      sinoNasalOutcomeTestText2:
        'You will find the patient\'s overall result of their SNOT-22 (reported in the section titled Physician Interpretation) with one of the following indications:',
      sinoNasalOutcomeTestText3:
        'Physicians can review each of the 22 answers to the SNOT-22 in the report section under the heading "Allergy Symptom Severity SNOT-22."',
      sinoNasalOutcomeTestMildTitle: 'Mild',
      sinoNasalOutcomeTestMildText: 'Score of 8-19',
      sinoNasalOutcomeTestModerateTitle: 'Moderate',
      sinoNasalOutcomeTestModerateText: 'Score between 20-50',
      sinoNasalOutcomeTestSevereTitle: 'Severe',
      sinoNasalOutcomeTestSevereText: 'Score greater than 50',
      asthmaControlTestTitle: 'Asthma Control Test (ACT)',
      asthmaControlTestText:
        'Chronic Rhinosinusitis (CRS) is strongly associated with comorbid asthma. The Asthma Control Test (ACT) quickly provides a numerical score to assess asthma control. The ACT is recognized by the National Institutes of Health (NIH) in its 2007 asthma guidelines and has been clinically validated against spirometry and specialist assessment (Nathan et al. 2004). Suppose your patient indicates that they have asthma in the general allergy history section. In that case, you will find the overall score to how well the patients asthma is under control from the ACT reported in the Physician Interpretation section. A score of 19 or less indicates that the patient\'s asthma might not be under control.',
      allergyMedicationUseTitle: 'Allergy Medication Use',
      allergyMedicationUseText:
        'Patients are often self-diagnosing and self-medicating inappropriately. The most commonly devised strategies for allergy symptoms are related to adherence to medications. Patients who self-medicate with OTC medications are likely to suffer from poor adherence rates, incorrect medications may be taken without medical or pharmacist direction. Self-management strategies can be better implemented when a patient’s medication type and use has been evaluated in combination with their symptom frequency and severity and environmental encounters.  The Allergy Assessment records the patients responses to medication use and frequency of antihistamines, decongestants, cromolyn, leukotriene receptor antagonists and combination long acting and beta2-adrenergic bronchodilators for patients that indicate they are asthmatic. ',
      healthBehaviorsTitle: 'Health Behaviors',
      healthBehaviorsTextFirst:
        'It is well known that a positive lifestyle including regular physical activity, weight control, good sleep hygiene, a healthy diet rich in fruits and vegetables, not using tobacco (and eliminating exposure to second-hand smoke), and psychological well-being can help allergy sufferers. Many patients know they need to make lifestyle changes but they don\'t often take it seriously until they hear it from a trusted medical professional. The Allergy Assessment also presents patients with questions related to their Health Behaviors and provides physicians with the patient\'s scores to physical activity and exercise levels, fruits and vegetable consumption, and tobacco use, as these health behaviors have obvious impact on a patients general wellbeing, weight management, and psychosocial health including quality of sleep.',
      healthBehaviorsTextSecond:
        'When patients are asymptomatic or have their pollen triggers under control, physicians should encourage their allergy and asthma patients achieve the recommended physical activity and exercise guidelines. Also, encouraging patients to spend time outside, especially in green spaces such as parks, forests, lakes, and beaches, when possible, to attract some good "bugs" into their microbiota is also beneficial to allergic disease management. Safe exposure to direct sunlight to soak up some natural vitamin D while exercising outdoors can also be beneficial.',
      healthBehaviorsTextThird:
        'Consuming a diet high in fermentable fiber, with fruits and vegetables and regular prebiotics, is highly beneficial and includes items such as garlic, onion, leek, chicory root, and asparagus. Probiotic-rich foods are also recommended and include fermented cabbage, sauerkraut or kimchi, pickles, buttermilk, and live-cultured yogurts. A healthy diet combined with prebiotics and probiotics may aid in allergy protection for your patients (Pullen and Grogan 2017).',
      healthBehaviorsTextForth:
        'In conclusion, the QHSLab Allergy Assessment provides a digital medicine tool for clinicians to quickly assess a patient’s likely allergy triggers and environmental conditions that affect their symptom severity and quality of life. With this simple digital health risk assessment, clinicians can determine if further confirmatory blood or skin tests are needed and if the patient would benefit from an allergy specialist involvement and/or allergen immunotherapy treatment approach.',
      referencesTitle: 'References',
      referencesFirst:
        'Galimberti et al. Catching allergy by a simple questionnaire. World Allergy Organization Journal (2015) 8:16',
      referencesSecond:
        'Hopkins C et al. Psychometric validity of the 22-item Sinonasal Outcome Test. Clin Otolaryngol. 2009 Oct;34(5):447-54.',
      referencesThird:
        'Toma S, Hopkins C. Stratification of SNOT-22 scores into mild, moderate, or severe and relationship with other subjective instruments. Rhinology. 2016 Jun;54(2):129-33.',
      referencesFourth:
        'US Department of Health and Human Services, National Institutes of Health, National Heart, Lung, and Blood Institute. Expert Panel Report 3: Guidelines for the Diagnosis and Management of Asthma (EPR-3 2007). ',
      referencesFifth:
        'Nathan RA et al. J Allergy Clin Immunol. 2004;113:59-65.',
      referencesSixth:
        'Pullen and Grogan. Allergies on the Rise Solutions from Primary care. MedScience Research Group, Inc. (2017).',
    },
    QScaleReport: {
      title: 'Q-Scale',
      subtitle: 'Stress Type Test & Kessler 6 Instruments',
      firstPart:
        'A major advancement in psychological research and epidemiology over the last 40 years has been the development of lay-administered structured interviews, allowing the standardized estimation of the prevalence of psychological disorders among non-clinical populations.',
      secondPart:
        '"Q" stands for “Quality of Life,” and the Q-Scale measures a patient\'s responses or early warning signs to questions about their sleep, stress, anxiety, worry, pain and satisfaction. QHSLabs created the Q-Scale instrument to generate information on psychosocial wellbeing in otherwise healthy patients. Thus, items in the Q-Scale have been deliberately written to emphasize normal psychological functioning in generally healthy patients. If responses to the Q-Scale indicate potential mental health troubles, patients are directed to the Stress Type Test and Kessler 6 questions to identify their risk of clinical anxiety and depression for further clinical evaluation.',
      thirdPart:
        'Some people manage their stress best physically (running, playing sports, going to the gym), and others do it best mentally (meditation, relaxation, cognitive behavioral therapy). Patients who screen positively to questions relating to "feeling under strain pressure" or "finding it difficult to relax\' are directed to the Stress Type Test. The Stress Type Test is a 14 item assessment that identifies how the patient may best respond to a physical or cognitive stress management approach.¹',
      fourthPart:
        'The Kessler Screening Scale for Psychological Distress (Kessler 6) has been used widely as a screener for mental health problems and to measure the severity of the impact of mental health problems on a patient\'s daily life. The Kessler 6 is an appropriate screening and severity measure for mood disorders. It also predicts health-related quality of life over and above that predicted by diagnoses alone. Respondents rate how often they felt nervous, hopeless, restless, or fidgety, so sad that nothing could cheer them up, that everything was an effort, and worthless.²',
      referencesTitle: 'References',
      referencesFirst:
        'Egger, Binns, Rossner, & Sagner. (2017) Lifestyle Medicine. Lifestyle, the Environment and Preventive Medicine in Health and Disease. Third Edition. Academic Press',
      referencesSecond:
        'Kessler RC, Andrews G, Colpe LJ, Hiripi E, Mroczek DK, Normand SL, Walters EE, Zaslavsky AM Psychol Med. 2002 Aug; 32(6):959-76',
    },
    pediatricAllergyBaselineAssessmentReport: {
      title: 'Pediatric Allergy Baseline Assessment Report',
      firstPart: `The QHSLab ‘Pediatric Allergy Assessment’ is a proprietary digital medicine tool developed to assist clinicians identify and deal with common allergic conditions, allergen triggers, symptom severity, medication use and frequency in children. The Pediatric Allergy Assessment agrees with the definition for using CPT code 96160, “Administration of patient-focused health risk assessment instrument (e.g., health hazard appraisal) with scoring and documentation, per standardized instrument.`,
      requirementsFirst: 'The requirements to report 96160 are as follows',
      requirementsSecond:
        'Practice expense is incurred to administer the instrument (such as nurse time or for purchase of the screen). The instrument is standardized. The instrument is scorable. The results are documented in the medical record.',
      requirementsThird:
        'The term “standardized” means previously validated tests that are administered and scored in a consistent or “standard” manner.',
      requirementsForth:
        'What constitutes “scoring.” This is an important distinction between a true health risk assessment and history gathering forms. The instrument, at a minimum, should provide a threshold result or pertinent yes/no responses that would indicate a patient’s risk for an adverse health outcome or risk for further exacerbation of a current, typically chronic, medical condition.',
      QHSLabAllergyAssessmentTitle: 'QHSLab Pediatric Allergy Assessment',
      QHSLabAllergyAssessmentText:
        'The Pediatric Allergy Assessment is a proprietary digital medicine application that combines several validated and standardized allergy and asthma-related instruments described below. This assessment includes parent and guardian responses and scoring related to their child’s allergy history, recent allergy and asthma symptoms, environmental impact and severity of these symptoms, and allergy and asthma medication use. The Pediatric Allergy Assessment was developed to help physicians and other healthcare providers in their routine evaluation of children suspected to have allergies and comorbid asthma. "Digital Medicine" is a term that relates to computerized applications that are directed by physicians and other healthcare providers. This assessment is not meant to be diagnostic or therapeutic, but to be used as an adjunct to clinical decision making.',
      respiratoryAllergyPredictionTestTitle:
        'Respiratory Allergy Prediction Test',
      respiratoryAllergyPredictionTestText:
        'The Respiratory Allergy Prediction Test is a validated and straightforward four item assessment. This is designed to confirm the clinical suspicion of respiratory allergy and the medical necessity of performing allergy testing by its high predictive value. Answering yes to the following three questions: 1) occurrence of symptoms in the springtime, 2) to have or have had a cough, wheezing and shortness of breath, and 3) to frequent use of nasal sprays, provides a high probability (85%) of a respiratory allergy diagnosis. It also suggests a real need for allergy testing to confirm the allergen trigger. If a patient answered yes to the questions just described. In that case, the physicians digital interpretation report will indicate the patient is highly likely to test positive to an allergy blood or skin test for confirmation of the environmental allergen triggers (Galimberti et al., 2015 & Sacchetti M et al).',
      generalAllergyHistoryTitle: 'General Allergy History',
      generalAllergyHistoryText:
        'A parent or guardians knowledge of their child’s various health conditions, or "health literacy," has been shown to benefit their approach to self-management and overall quality of life. Suppose a parent or guardian answers that they do not know if their child has a health condition such as Allergic Rhinitis, Asthma, Allergic Conjunctivitis, Allergic Urticaria, food allergy or anaphylaxis. This situation could provide an educational opportunity to explain the condition, determine if the child is suffering from the condition, and explain proper management of the condition. If a parent or guardian answers that their child has the condition and treated in the past, this will provide insight into their expectation of the current treatment to be proposed. Both of these responses are opportunities to reinforce evidence-based approaches to allergy management.',
      patientReportedAllergySymptomsTitle:
        'Parent or Guardian Reported Child’s Allergy Symptoms',
      patientReportedAllergySymptomsText:
        'Allergens that cause symptoms can be broadly categorized as predominantly outdoor, indoor, or food-related. Different outdoor allergens pollinate at various times during the year. Trees produce pollen in the early to mid-springtime, grass pollination occurs later in spring through summer, and weeds pollinate in the fall season. Outdoor mold spores can also affect allergy sufferers year round when conditions suit fungi or mold growth, such as after the winter frost melts in early spring or after heavy rain periods followed by warmer weather conditions. Perennial allergens, such as dust mites, animal dander, insects, and mold allergens, which are likely to be found indoors in areas with high moisture or humidity conditions are very prevalent year round allergy symptom triggers. A parent or guardian’s responses to questions regarding their child’s symptoms, occurrence, and environment help physicians determine the likely allergen to be affecting the child, to be confirmed through a skin or blood test, and also helps the physician prescribe or modify medications.',
      sinoNasalOutcomeTestTitle: 'Sino-Nasal Outcome Test-22 (SNOT-22)',
      sinoNasalOutcomeTestText1:
        'The Sino-Nasal Outcome Test-22 (SNOT-22) is a commonly utilized disease-specific questionnaire involving 22 symptoms that combine rhinologic issues with general health issues. The SNOT-22 has been validated and has been proven easy to use throughout the world in clinical practice and research. Physicians can use this test to facilitate routine clinical practice to highlight chronic rhinosinusitis\'s impact on patients\' quality of life. This test is also frequently used to measure the outcome of surgical intervention and specific allergen immunotherapy treatment (Hopkins et al., 2009).',
      sinoNasalOutcomeTestText2:
        'You will find the patient\'s overall result of their SNOT-22 (reported in the section titled Physician Interpretation) with one of the following indications:',
      sinoNasalOutcomeTestText3:
        'Physicians can review each of the 22 answers to the SNOT-22 in the report section under the heading "Allergy Symptom Severity SNOT-22."',
      sinoNasalOutcomeTestMildTitle: 'Mild',
      sinoNasalOutcomeTestMildText: 'Score of 8-19',
      sinoNasalOutcomeTestModerateTitle: 'Moderate',
      sinoNasalOutcomeTestModerateText: 'Score between 20-50',
      sinoNasalOutcomeTestSevereTitle: 'Severe',
      sinoNasalOutcomeTestSevereText: 'Score greater than 50',
      asthmaControlTestTitle: 'Asthma Control Test (ACT)',
      asthmaControlTestText:
        'Chronic Rhinosinusitis (CRS) is strongly associated with comorbid asthma. The Asthma Control Test (ACT) quickly provides a numerical score to assess asthma control. The ACT is recognized by the National Institutes of Health (NIH) in its 2007 asthma guidelines and has been clinically validated against spirometry and specialist assessment (Nathan et al. 2004). Suppose your patient indicates that they have asthma in the general allergy history section. In that case, you will find the overall score to how well the patients asthma is under control from the ACT reported in the Physician Interpretation section. A score of 19 or less indicates that the patient\'s asthma might not be under control.',
      allergyMedicationUseTitle: 'Allergy Medication Use',
      allergyMedicationUseText:
        'Parents and guardians are often self-diagnosing and self-medicating their children inappropriately. The most commonly devised strategies for allergy symptoms are related to adherence to medications. Patients who self-medicate with OTC medications are likely to suffer from poor adherence rates, incorrect medications may be taken without medical or pharmacist direction. Self-management strategies can be better implemented when a patient’s medication type and use has been evaluated in combination with their symptom frequency and severity and environmental encounters. The Pediatric Allergy Assessment records the parent or guardians responses to their child’s medication use and frequency of antihistamines, decongestants, cromolyn, leukotriene receptor antagonists and combination long acting and beta2-adrenergic bronchodilators for patients that indicate they are asthmatic. \n In conclusion, the QHSLab Pediatric Allergy Assessment provides a digital medicine tool for clinicians to quickly assess a patient’s likely allergy triggers and environmental conditions that affect their symptom severity and quality of life. With this simple digital health risk assessment, clinicians can determine if further confirmatory blood or skin tests are needed and if the patient would benefit from an allergy specialist involvement and/or allergen immunotherapy treatment approach.',
      referencesTitle: 'References',
      referencesFirst:
        'Galimberti et al. Catching allergy by a simple questionnaire. World Allergy Organization Journal (2015) 8:16',
      referencesSecond:
        'Sacchetti M et al. Development and preliminary validation of a new screening questionnaire for identifying atopic children. Pediatric Health, Medicine and Therapeutics 2017:8 99–105',
      referencesThird:
        'Hopkins C et al. Psychometric validity of the 22-item Sinonasal Outcome Test. Clin Otolaryngol. 2009 Oct;34(5):447-54.',
      referencesFourth:
        'Toma S, Hopkins C. Stratification of SNOT-22 scores into mild, moderate, or severe and relationship with other subjective instruments. Rhinology. 2016 Jun;54(2):129-33.',
      referencesFifth:
        'US Department of Health and Human Services, National Institutes of Health, National Heart, Lung, and Blood Institute. Expert Panel Report 3: Guidelines for the Diagnosis and Management of Asthma (EPR-3 2007).',
      referencesSixth:
        'Nathan RA et al. J Allergy Clin Immunol. 2004;113:59-65.',
    },
    SNOT22Report: {
      title: 'SNOT-22 Assessment',
      firstPart: `SNOT-22 is a disease-specific questionnaire involving 22 symptoms that combine rhinologic issues with general health issues, which provides an overall symptom severity score that may be impacting a patients quality of life.`,
      sinoNasalOutcomeTestTitle: 'Sino-Nasal Outcome Test-22 (SNOT-22)',
      sinoNasalOutcomeTestText1:
        'The Sino-Nasal Outcome Test-22 (SNOT-22) is a commonly utilized disease-specific questionnaire involving 22 symptoms that combine rhinologic issues with general health issues. The SNOT-22 has been validated and has been proven easy to use throughout the world in clinical practice and research. Physicians can use this test to facilitate routine clinical practice to highlight chronic rhinosinusitis\'s impact on patients\' quality of life. This test is also frequently used to measure the outcome of surgical intervention and specific allergen immunotherapy treatment (Hopkins et al., 2009).',
      sinoNasalOutcomeTestText2:
        'You will find the patient\'s overall result of their SNOT-22 (reported in the section titled Physician Interpretation) with one of the following indications:',
      sinoNasalOutcomeTestText3:
        'Physicians can review each of the 22 answers to the SNOT-22 in the report section under the heading "Allergy Symptom Severity SNOT-22."',
      sinoNasalOutcomeTestMildTitle: 'Mild',
      sinoNasalOutcomeTestMildText: 'Score of 8-19',
      sinoNasalOutcomeTestModerateTitle: 'Moderate',
      sinoNasalOutcomeTestModerateText: 'Score between 20-50',
      sinoNasalOutcomeTestSevereTitle: 'Severe',
      sinoNasalOutcomeTestSevereText: 'Score greater than 50',
      referencesTitle: 'References',
      referencesFirst:
        'Hopkins C et al. Psychometric validity of the 22-item Sinonasal Outcome Test. Clin Otolaryngol. 2009 Oct;34(5):447-54.',
    },
    asthmaControlAssessmentReport: {
      title: 'Asthma Control Test (ACT)',
      firstPart: `The Asthma control test is a 5 item assessment that indicates how well a patients Asthma control has been over the past month.`,
      asthmaControlTestTitle: 'Asthma Control Test (ACT)',
      asthmaControlTestText:
        'Chronic Rhinosinusitis (CRS) is strongly associated with comorbid asthma. The Asthma Control Test (ACT) quickly provides a numerical score to assess asthma control. The ACT is recognized by the National Institutes of Health (NIH) in its 2007 asthma guidelines and has been clinically validated against spirometry and specialist assessment (Nathan et al. 2004).',
      asthmaControlTestText2:
        'A score of 19 or less indicates that the patient\'s asthma might not be under control.\n',
      referencesTitle: 'References',
      referencesFirst:
        'US Department of Health and Human Services, National Institutes of Health, National Heart, Lung, and Blood Institute. Expert Panel Report 3: Guidelines for the Diagnosis and Management of Asthma (EPR-3 2007).',
      referencesSecond:
        'Nathan RA et al. J Allergy Clin Immunol. 2004;113:59-65.',
    },
    printReport: {
      assessmentDate: 'Assessment Date',
      dateOfBirth: 'Date of Birth',
      sex: 'Sex',
      male: 'Male',
      female: 'Female',
      MRN: 'MRN',
      zipCode: 'Zip code',
      providerName: 'Provider Name',
      providerNPI: 'Provider NPI',
      accountAddress: 'Account Address',
    },
    printBilling: {
      billingReport: 'Billing Report',
      procedureName: 'Procedure Name',
      procedureDescription: 'Procedure Description',
      exampleCPT: 'Example CPT',
      completedTimestamp: 'Date Completed',
      reviewedTimestamp: 'Provider Review Date',
      dateOfBirth: 'Date of Birth',
      sex: 'Sex',
      male: 'Male',
      female: 'Female',
      MRN: 'MRN',
      zipCode: 'Zip code',
      providerName: 'Provider Name',
      accountAddress: 'Account Address',
    },
    charts: {
      '< 1': '< 1',
      '1-4': '1-4',
      '5-14': '5-14',
      '15-24': '15-24',
      '25-34': '25-34',
      '35-44': '35-44',
      '45-54': '45-54',
      '55-64': '55-64',
      '65-74': '65-74',
      '75-84': '75-84',
      '> 84': '> 84',
      genderFemale: 'Gender (Female)',
      averageAge: 'Average age',
      generalHealth: 'General Health',
      symptomSeveritySnot22: 'Symptom Severity (SNOT-22)',
      physicalActivity: 'Physical Activity',
      fruitVegetableConsumption: 'Fruit and Vegetable Consumption',
      tobaccoUse: 'Tobacco Use',
      ageRange: 'Age Range',
      asthma: 'Asthma',
      allergicRhinitis: 'Allergic Rhinitis',
      allergicUrticara: 'Allergic Urticara',
      allergicConjunctivitis: 'Allergic Conjunctivitis',
      numberOfPatients: 'Number of patients',
      good: 'Good',
      fair: 'Fair',
      very_good: 'Very Good',
      excellent: 'Excellent',
      poor: 'Poor',
      do_not_know: 'I do not know if I have this condition',
      know_i_dont_have: 'I know I do not have this condition',
      never_treated: 'I have this condition but never treated for it',
      treated_in_past: 'I have this condition and treated in the past',
      treated_monitored:
        'I am treated and monitored regulary for this condition',
      low: 'Low',
      lowRisk: 'Low Risk',
      moderate: 'Moderate',
      moderateRisk: 'Moderate Risk',
      highRisk: 'High Risk',
      mild: 'Mild',
      severe: 'Severe',
    },
    fileUploading: {
      dragFileHere: 'DRAG FILE HERE OR',
      availableFormats: '* available file format - ".XLSX"',
      fileIsUploaded: 'The file is uploaded and validated...',
      fileSuccessfullyValidated: 'File successfully validated',
      importPatientsNow: 'Import Patients Now',
      importInsuranceNow: 'Import Insurance Now',
      fileTypeNotSupported: 'File type not supported',
      downloadValidationErrors: 'download validation errors',
      fileHasValidationErrors: 'Uploaded file has validation errors',
      uploadAndValidate: 'Upload And Validate',
      selectFile: 'Select File',
      uploadOneFile: 'Please upload one file',
      importWasSuccessful: 'Import was successful',
      ok: 'ok',
      error500:
        'The string \'United States\' was not recognized as a valid DateTime. There is an unknown word starting at index \'0\'',
    },
  },
};
