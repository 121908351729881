import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFetchList from 'hooks/useFetchList';
import { Box, Checkbox, IconButton, makeStyles, Typography } from '@material-ui/core';
import Modal from '../../../Shared/Modal';
import VirtualizedTable from '../../../Table/VirtualizedTable';
import TablePlaceholder from '../../../Shared/TablePlaceholder';
import accountsPlaceholderImage from 'assets/placeholders/accounts.svg';
import { useSelector } from 'react-redux';
import getFakeDataList from '../../../Shared/FakeDataGenerator';
import { FAKE_ASSESSMENT } from '../../../Assessments/constants';
import { SortDirection } from 'react-virtualized';
import RoundedButton from '../../../Shared/RoundedButton';
import { ALLOWED_ACCOUNT_COLUMNS, ALLOWED_ACCOUNT_FIELDS } from '../../constants';
import { TABLE_KEYS } from '../../../../redux_store/reducer/reducers/tableColumnsReducer';
import { getLocalizedLabel } from '../../../../helpers/localize';
import { CUSTOM_TAGS_OPTIONS } from '../../../Shared/CustomTags';
import BlurScreen from '../../../Shared/blur_screen';

const useStyles = makeStyles((theme) => ({
  modal: {
    maxWidth: 1000,
  },
  text: {
    marginLeft: 16,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    width: '100%',
  },
  selectAllCheckBox: {
    padding: 0,
    marginRight: 46,
    marginLeft: 6,
  },
  tableContainer: {
    height: 500,
    width: 750,
    padding: 10,
    paddingTop: 0,
    borderRadius: 10,
    border: '1px solid rgba(0, 0, 0, 0.07)',
  },
  iconPadding: {
    borderRadius: 20,
  },
}));


const AllowedAccountsSectionModal = ({
                                       values,
                                       changeAllowedAccounts,
                                       toggleAllowedAccountsModal,
                                       openAllowedAccountsModal,
                                     }) => {
    const { t } = useTranslation([
      'titles',
      'btn',
    ]);
    const classes = useStyles();
    const [includedAccountsUUIDs, setIncludedAccountsUUIDs] = useState([]);
    const [excludedAccountsUUIDs, setExcludedAccountsUUIDs] = useState([]);
    const { metadata: { currentAccountPermissions } } = useSelector((state) => state.user);

    useEffect(() => {
      if (values?.allowedAccounts) {
        setIncludedAccountsUUIDs(values?.allowedAccounts ?? []);
      }
    }, [values?.allowedAccounts]);

    const baseEndpoint = `/allowedAccounts/users/${values?.userUUID ?? 'empty'}`;
    const {
      items,
      totalAccountsCount,
      totalCount,
      completionRate,
      reloadData,
      clearFilter,
      filter,
      loading: dataLoading,
      ...restFetchProps
    } = useFetchList({
      baseEndpoint,
      baseParams: {
        fields: ALLOWED_ACCOUNT_FIELDS.join(),
        q: undefined,
      },
      baseSort: {
        sortBy: 'businessName',
        sortDirection: SortDirection.DESC,
      },
      baseFilter: {},
      customMethod: 'POST',
      patches: {
        'includedAccountsUUIDs': includedAccountsUUIDs,
        'excludedAccountsUUIDs': excludedAccountsUUIDs,
      },
    });

    const FAKE_ASSESSMENT_LIST = getFakeDataList(FAKE_ASSESSMENT);

    const closeModal = () => {
      toggleAllowedAccountsModal(false);
      setIncludedAccountsUUIDs(values?.allowedAccounts ?? []);
      setExcludedAccountsUUIDs([]);
    };

    const onRowClick = (props) => {
      let newIncludedAccounts = includedAccountsUUIDs;
      let newExcludedAccounts = excludedAccountsUUIDs;
      if (includedAccountsUUIDs.includes(props.uuid)) {
        newIncludedAccounts = newIncludedAccounts.filter(item => item !== props.uuid);
        newExcludedAccounts = [...newExcludedAccounts, props.uuid];
      } else {
        newIncludedAccounts = [...newIncludedAccounts, props.uuid];
        newExcludedAccounts = newExcludedAccounts.filter(item => item !== props.uuid);
      }
      setIncludedAccountsUUIDs(newIncludedAccounts);
      setExcludedAccountsUUIDs(newExcludedAccounts);
    };

    const onSelectAllFilteredClick = (allSelectedInTheList) => {
      let newIncludedAccounts = includedAccountsUUIDs;
      let newExcludedAccounts = excludedAccountsUUIDs;

      if (allSelectedInTheList) {
        newExcludedAccounts = [...new Set([...excludedAccountsUUIDs, ...accounts.map(item => item.uuid)])];
        newIncludedAccounts = newIncludedAccounts.filter(item => !newExcludedAccounts.includes(item));
      } else {
        newIncludedAccounts = [...new Set([...includedAccountsUUIDs, ...accounts.map(item => item.uuid)])];
        newExcludedAccounts = newExcludedAccounts.filter(item => !newIncludedAccounts.includes(item));
      }

      setIncludedAccountsUUIDs(newIncludedAccounts);
      setExcludedAccountsUUIDs(newExcludedAccounts);
    };

    const accounts =
      items &&
      items.map((account) => {
        return {
          ...account,
          customTags: account.customTags ? account.customTags.map(item => getLocalizedLabel(
            CUSTOM_TAGS_OPTIONS,
            item,
          )) : [],
        };
      });


    const allSelectedInTheList = useMemo(
      () => {
        return !accounts?.some(item => !includedAccountsUUIDs.includes(item.uuid));
      },
      [accounts, includedAccountsUUIDs, excludedAccountsUUIDs],
    );

    const hasFilter = useMemo(
      () => {
        return Object.values(filter)
          .map(item => Array.isArray(item) ? item.join('') : item)
          .join('').length > 0;
      },
      [filter],
    );

    return (<>
        <Modal
          open={openAllowedAccountsModal}
          classes={{ paper: classes.modal }}
          title={t('titles:allowedAccounts')}
          handleClose={closeModal}
        >
          <Box p={3} display='flex' flexDirection={'column'}>
            <Box p={2} display='flex' flexDirection={'row'} alignItems={'center'}
                 justifyContent='space-between'>
              <Typography className={classes.text}>
                {t('forms:accountAccessTotalNumber', {
                  number: includedAccountsUUIDs.length === 0
                    ? 'all'
                    : includedAccountsUUIDs.length,
                  end: includedAccountsUUIDs.length === 1
                    ? ''
                    : 's',
                })}
              </Typography>
              <BlurScreen isBlurShown={dataLoading} containerStyle={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
                <IconButton
                  disabled={!accounts || !accounts.length}
                  className={classes.iconPadding}
                  onClick={accounts && accounts.length ? () => onSelectAllFilteredClick(allSelectedInTheList) : null}
                >
                  <Checkbox
                    name={'selectAll'}
                    checked={allSelectedInTheList && !!accounts && !!accounts.length}
                    color='primary'
                    style={{
                      padding: 0,
                      margin: 0,
                    }}
                  />
                  <Typography className={classes.text} style={{
                    margin: '0 0 0 8px',
                  }}>
                    {
                      allSelectedInTheList && accounts && accounts.length
                        ? t('forms:unselectAllFilteredItems', {
                          filtered: hasFilter ? 'Filtered ' : '',
                        })
                        : t('forms:selectAllFilteredItems', {
                          filtered: hasFilter ? 'Filtered ' : '',
                        })
                    }
                  </Typography>
                </IconButton>
              </BlurScreen>
            </Box>
            <div className={classes.tableContainer}>
              <VirtualizedTable
                tableKey={TABLE_KEYS.allowedAccounts}
                fakeRows={FAKE_ASSESSMENT_LIST}
                rowCount={items?.length ?? 0}
                rows={accounts ?? []}
                columns={ALLOWED_ACCOUNT_COLUMNS}
                onRowClick={onRowClick}
                FetchProps={{
                  filter,
                  ...restFetchProps,
                }}
                hideContextMenu={true}
                currentAccountPermissions={currentAccountPermissions}
                onDataLoading={!items || dataLoading}
                placeholder={
                  <TablePlaceholder
                    image={accountsPlaceholderImage}
                    itemsNotFoundText={t('placeholders:noItemsFound', {
                      item: t('titles:accounts'),
                    })}
                    message={t('placeholders:reviseFiltersOrCreateYourFirstItem', {
                      item: t('titles:account'),
                    })}
                  />
                }
              />
            </div>
            <Box p={2} display='flex' justifyContent='space-between'>
              <RoundedButton
                variant='outlined'
                color='primary'
                size='small'
                onClick={closeModal}
              >
                {t('btn:cancel')}
              </RoundedButton>
              <RoundedButton
                variant='contained'
                color='primary'
                size='small'
                onClick={() => {
                  changeAllowedAccounts(includedAccountsUUIDs);
                  closeModal();
                }}
              >
                {t('btn:applyChanges')}
              </RoundedButton>
            </Box>
          </Box>
        </Modal>
      </>
    );
  }
;

export default AllowedAccountsSectionModal;
